import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51NBhzRLfPz0vvpfjq27jEobBSk0oHhNEcmV9ihgZH85wedIsse6IsPqPQEhbz3IuqUFe8XDV42qkz1xMYYbbmF7W00GsFRbYml"
); // Replace with your actual Stripe publishable key

// Define styling variables
const containerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center", // Center vertically and horizontally
};

const headingStyle = {
  fontSize: "30px",
  marginBottom: "20px",
  color: "grey",
  fontWeight: "bold",
};

const userInfoStyle = {
  fontSize: "15px",
  margin: 0,
};

const totalPriceStyle = {
  fontSize: "15px",
  margin: 0,
};

const successMessageStyle = {
  fontSize: "20px",
  color: "green",
};

const buttonStyle = {
  alignSelf: "center",
  backgroundColor: "#4CAF50",
  color: "white",
  fontSize: "18px",
  padding: "10px 20px",
  border: "none",
  cursor: "pointer",
  borderRadius: 10,
  width: "45%",
  marginTop: 20,
};
const formStyle = {
  width: "70%",
  backgroundColor: "",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  border: "0px solid #ccc",
  borderRadius: "10px",
  margin: 0,
};

const inputStyle = {
  width: "95%",
  height: 40,
  padding: "10px",
  marginBottom: "10px",
  border: "1px solid #ccc",
  borderRadius: "10px",
};

const cardElementStyle = {
  width: "100%",
  paddingLeft: 2,
  paddingRight: 2,
  height: "60px",
  backgroundColor: "white",
  marginBottom: "10px",
  border: "1px solid #ccc",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const iconStyle = {
  marginRight: "8px", // Adjust the margin as needed for spacing between the icon and text
  fontSize: "12px", // Adjust the icon size as needed
};

const userInfoStyleContainer = {
  margin: "0 auto",
  width: "90%",
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  backgroundColor: "white", // Add white background
  border: "1px solid #ccc", // Add 1px border
  borderRadius: "10px", // Add border radius for rounded corners
  padding: "10px", // Add padding for spacing
};
function PaymentForm({
  total,
  onSuccess,
  selectedCity,
  selectedParking,
  selectedDuration,
  currentDate,
  numUnits,
  futureDate,
  licensePlate,
  email,
  selectedPaymentMethod,
  setIsPaymentOpen,
  setSelectedCity,
  setSelectedParking,
  setSelectedPaymentMethod,
  setNumUnits,
  setCurrentDateTime,
  setSelectedDuration,
  setPrivacyChecked,
  setTermsChecked,
  setEmail,
  setLicensePlate,
}) {
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardElement, setCardElement] = useState(null);
  const [cardholderName, setCardholderName] = useState("");
  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    const initializeCardElement = async () => {
      // Initialize Stripe with your publishable key
      const stripe = await stripePromise;

      // Create a PaymentMethodElement
      const elements = stripe.elements();
      const element = elements.create("card", {
        style: {
          base: {
            iconColor: "rgb(240, 57, 122)",
            color: "rgb(240, 57, 122)",
            fontSize: "16px",
            fontFamily: '"Open Sans", sans-serif',
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#CFD7DF",
            },
          },
          invalid: {
            color: "#e5424d",
            ":focus": {
              color: "#303238",
            },
          },
        },
      });

      // Mount the CardElement to the DOM
      element.mount("#card-element");
      setCardElement(element);
    };

    initializeCardElement();
  }, []);

  const handlePayment = async () => {
    try {
      if (isSubmitting || !cardElement) {
        return;
      }

      setIsSubmitting(true); // Disable the submit button
      const stripe = await stripePromise;
      const response = await fetch(
        "https://api.stripe.com/v1/payment_intents",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${"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"}`, // Replace with your actual Stripe secret key
          },
          body: `amount=${total * 100}&currency=eur`, // Adjust the currency as needed
        }
      );

      const data = await response.json();

      const { paymentMethod, error } = await stripe.confirmCardPayment(
        data.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardholderName, // Updated to use the cardholderName state variable
            },
            // Specify the receipt email here
          },
          receipt_email: email, // Updated to use user's email
        }
      );

      setIsSubmitting(false); // Re-enable the submit button

      if (error) {
        setPaymentError(error.message);
      } else {
        // Payment succeeded
        setPaymentSuccessful(true);
        onSuccess();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>MODULO DI PAGAMENTO</h2>
      {paymentSuccessful ? (
        // Display user information when payment is successful
        <div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>🏙️</span>
            <p style={userInfoStyle}>
              Città: <strong>{selectedCity}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>🚗</span>
            <p style={userInfoStyle}>
              Parcheggio: <strong>{selectedParking}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>⏳</span>
            <p style={userInfoStyle}>
              Durata: <strong>{selectedDuration}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>#️⃣</span>
            <p style={userInfoStyle}>
              Qta: <strong>{numUnits}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>📅</span>
            <p style={userInfoStyle}>
              Tempo di partenza: <strong>{currentDate.toString()}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>📆</span>
            <p style={userInfoStyle}>
              Orario di fine: <strong>{futureDate.toString()}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>🚗</span>
            <p style={userInfoStyle}>
              Targa: <strong>{licensePlate}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>✉️</span>
            <p style={userInfoStyle}>
              E-mail: <strong>{email}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>💳</span>
            <p style={userInfoStyle}>
              Metodo di pagamento selezionato:{" "}
              <strong>{selectedPaymentMethod}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>💶</span>
            <p style={totalPriceStyle}>
              Prezzo totale: <strong>€{total}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>💶</span>
            <p style={totalPriceStyle}>
              Pagamento riuscito: <strong>SÌ</strong>
            </p>
          </div>
          <div
            style={{
              ...userInfoStyleContainer,
              backgroundColor: "",
              flexDirection: "column",
            }}
          >
            <b>**IMPORTANTE**</b>
            <p style={{ margin: 0 }}>
              ESPORRE SUL CRUSCOTTO UN AVVISO BEN VISIBILE DI AVVENUTO PAGAMENTO
              DELLA SOSTA CON L' APP MOVEA SIMPLE PAY.
            </p>
          </div>
          <button
            style={{
              ...userInfoStyleContainer,
              backgroundColor: "greenyellow",
              width: "40%",
              cursor: "pointer",
              marginTop: 20,
              textAlign:'center',
              justifyContent:'center'
            }}
            onClick={() => {
              setIsPaymentOpen(false);
              setSelectedCity("");
              setSelectedParking("");
              setCurrentDateTime(new Date());
              setSelectedDuration("ORARIO");
              setSelectedPaymentMethod("");
              setNumUnits(1);
              setTermsChecked(false);
              setPrivacyChecked(false);
              setEmail("");
              setLicensePlate("");
            }}
          >
            COMPRA UN ALTRO BIGLIETTO
          </button>
        </div>
      ) : (
        // Display the payment form when payment is not successful

        <div style={formStyle} id="payment-form">
          <div
            style={{
              ...userInfoStyleContainer,
              marginBottom: 20,
              backgroundColor: "",
              border: "0px",
            }}
          >
            <p style={{ ...totalPriceStyle, fontSize: 22 }}>
              Prezzo totale: <strong>€{total}</strong>
            </p>
          </div>
          <div style={{ width: "100%" }}>
            {/* Container to set equal width */}
            <input
              type="text"
              placeholder="NOME SULLA CARTA"
              style={inputStyle}
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
            />
          </div>
          {paymentError && (
            <p style={{ fontSize: "16px", color: "red", margin: 2 }}>
              {paymentError}
            </p>
          )}{" "}
          {/* Container to set equal width */}
          <div style={cardElementStyle} id="card-element"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <button
              type="button"
              style={buttonStyle}
              onClick={handlePayment}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sottomissione..." : `Paga €${total}`}
            </button>
            <button
              type="button"
              style={buttonStyle}
              onClick={() => {
                setIsPaymentOpen(false);
              }}
            >
              Indietro
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentForm;
