import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import "../styles.css";
import image from "../assets/imgg.png";
import HoverPopupDiv from "./HoverPopupDiv ";
import CustomInput from "./CustomInput ";
import { FaCar, FaEnvelope, FaCalendar } from "react-icons/fa";
import { FaCreditCard, FaPaypal } from "react-icons/fa";
import Switch from "react-switch";
import { ref, get, database, set, push } from "../config/firebase";
import PaymentForm from "./PaymentForm";
import { useNavigate } from "react-router-dom";
import PayPalButton from "./PayPalButton";
import emailjs from "@emailjs/browser";

import { parse } from "date-fns";
import axios from "axios";
const DurationSelector = ({
  selectedDuration,
  numUnits,
  onIncrement,
  onDecrement,
  onChange,
  isSmallScreen, // Receive isSmallScreen as a prop
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "10px",
      marginTop: 10,
      marginBottom: 10,
      border: "0px solid green",
      height: 50,
    }}
  >
    <div
      style={{
        backgroundColor: "#c0c0c0",
        width: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        borderRadius: "10px",
        color: "black",
      }}
    >
      <p
        style={{
          color: "black",
          fontSize: 17, // Conditionally set font size
          fontWeight: "bold",
        }}
      >
        Qtà:
      </p>
    </div>

    <input
      type="number"
      readOnly
      value={numUnits}
      onChange={onChange}
      min={0}
      max={
        selectedDuration === "ORARIO"
          ? 23
          : selectedDuration === "SETTIMANALE"
          ? 3
          : 6
      }
      style={{
        flex: 3,
        marginRight: "0px",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 15,
        border: "none",
        WebkitAppearance: "none",
      }}
    />
    <div
      style={{
        backgroundColor: "#c0c0c0",
        borderRadius: "10px",
        width: isSmallScreen ? "25%" : "25%", // Adjust width based on isSmallScreen
        display: "flex",
        justifyContent: "space-evenly", // Use space-between to distribute space evenly between buttons
        alignItems: "center",
        height: "100%", // Set the height to 100% to cover the entire parent div
      }}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          onIncrement();
        }}
        style={{
          backgroundColor: "black",
          margin: 0,
          width: "37%", // Adjust the button width
          height: "60%",
          fontWeight: "bold",
          color: "white",
          fontSize: isSmallScreen ? 14 : 20, // Adjust font size
          borderWidth: 0,
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center the text vertically
        }}
      >
        +
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          onDecrement();
        }}
        style={{
          backgroundColor: "black",
          margin: 0,
          width: "37%", // Adjust the button width
          height: "60%",
          fontWeight: "bold",
          color: "white",
          fontSize: isSmallScreen ? 14 : 20, // Adjust font size
          borderWidth: 0,
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center the text vertically
        }}
      >
        -
      </button>
    </div>
  </div>
);

const Logo = () => {
  return (
    <div style={{ marginBottom: "0px", marginTop: 10 }}>
      <img
        src={logo}
        alt="Logo"
        style={{ width: "200px", height: "100px", objectFit: "contain" }}
      />
    </div>
  );
};

const Image = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <img
        src={image}
        alt="Logo"
        style={{ width: "650px", height: "500px", objectFit: "contain" }}
      />
    </div>
  );
};

const popupContent = (
  <div>
    <h3>Details</h3>
    <p>This is the detailed information you wanted to show.</p>
  </div>
);

const CustomerHome = () => {
  const navigate = useNavigate();
  const [licensePlate, setLicensePlate] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [cities, setCities] = useState([]); // State variable to store the list of cities
  const [selectedCity, setSelectedCity] = useState(""); // State variable to store the selected city
  const [parkingOptions, setParkingOptions] = useState([]); // State variable to store parking names
  const [selectedParking, setSelectedParking] = useState(""); // State variable to store the selected parking
  const [parkingDetails, setParkingDetails] = useState({}); // State variable to store parking details
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [startingTime, setStartingTime] = useState(new Date());
  const [endingTime, setendingTime] = useState(new Date());
  const [selectedDuration, setSelectedDuration] = useState("ORARIO");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [numUnits, setNumUnits] = useState(1);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(true);
  const [checkButton, setCheckButton] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [slot1StartingTime, setSlot1] = useState("");
  const [cost, setCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paypalButton, setPaypalButton] = useState(false);
  const [CostContain, setCostContain] = useState({ hourly: 0, daily: 0 });

  const validateEmail = (email) => {
    // Regular expression for a valid email address
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const isValid = validateEmail(newEmail);
    setIsValidEmail(isValid);
  };
  function italianTimeFormat(dateUTC) {
    if (dateUTC) {
      const daysOfWeek = [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ];

      let jsDateFormat = new Date(dateUTC);
      let fullStringTime = {
        day: Number(jsDateFormat.getDate() < 10)
          ? "0" + jsDateFormat.getDate()
          : jsDateFormat.getDate(),
        month:
          Number(jsDateFormat.getMonth() + 1) < 10
            ? "0" + (jsDateFormat.getMonth() + 1)
            : jsDateFormat.getMonth() + 1,
        year: jsDateFormat.getFullYear(),
        hours:
          Number(jsDateFormat.getHours()) < 10
            ? "0" + jsDateFormat.getHours()
            : jsDateFormat.getHours(),
        minutes:
          Number(jsDateFormat.getMinutes()) < 10
            ? "0" + jsDateFormat.getMinutes()
            : jsDateFormat.getMinutes(),
        dayOfWeek: daysOfWeek[jsDateFormat.getDay()],
      };
      return (
        fullStringTime.dayOfWeek +
        ", " +
        fullStringTime.day +
        "/" +
        fullStringTime.month +
        "/" +
        fullStringTime.year +
        " " +
        fullStringTime.hours +
        ":" +
        fullStringTime.minutes
      );
    }
    return null;
  }

  /*const sendEmail = async (
    email,
    selectedDuration,
    numUnits,
    currentDateTime,
    licensePlate,
    selectedCity,
    selectedParking,
    cost,
    selectedPaymentMethod
  ) => {
    const serviceId = "service_c75zadc"; // Replace with your service ID
    const templateId = "template_pc73tph"; // Replace with your template ID
    const userId = "R00wYyZX6b2ZVrp4q"; // Replace with your user ID

    // Send the email
    emailjs
      .send(
        serviceId,
        templateId,
        {
          to_email: email,
          message: `Ecco i dettagli del tuo biglietto del parcheggio:
  
    Durata selezionata: ${selectedDuration}
    Numero di unità: ${numUnits}
    Ora di inizio: ${currentDateTime.toString()}
    Ora di fine: ${italianTimeFormat(new Date()).toString()}
    Targa: ${licensePlate}
    Città selezionata: ${selectedCity}
    Parcheggio selezionato: ${selectedParking}
    Prezzo: ${cost * numUnits} EUR
    Metodo di pagamento: ${selectedPaymentMethod}
    Pagato: SÌ`,
        },
        userId
      )
      .then((response) => {
        console.log("Email sent successfully!", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };*/
  const handlePaymentSuccess = async () => {
    setPaymentSuccess(true);
    const customerKey = push(ref(database, `customers/`)).key;
    if (parkingDetails.slotUnits === 2 && selectedDuration === "ORARIO") {
      const currentTime = new Date();
      const slot1Start = new Date(currentTime);
      slot1Start.setHours(
        Number(parkingDetails.slot1StartingTime.split(":")[0]),
        Number(parkingDetails.slot1StartingTime.split(":")[1])
      );
      const slot1End = new Date(currentTime);
      slot1End.setHours(
        Number(parkingDetails.slot1EndingTime.split(":")[0]),
        Number(parkingDetails.slot1EndingTime.split(":")[1])
      );
      const slot2Start = new Date(currentTime);
      slot2Start.setHours(
        Number(parkingDetails.slot2StartingTime.split(":")[0]),
        Number(parkingDetails.slot2StartingTime.split(":")[1])
      );
      const slot2End = new Date(currentTime);
      slot2End.setHours(
        Number(parkingDetails.slot2EndingTime.split(":")[0]),
        Number(parkingDetails.slot2EndingTime.split(":")[1])
      );
      let endingTime;
      if (currentTime > slot1Start && currentTime < slot1End) {
        endingTime = slot1End;
      } else {
        endingTime = slot2End;
      }

      const timeDiff = endingTime - currentTime; // Difference in milliseconds
      const hoursRemaining = Math.abs(timeDiff / (1000 * 60 * 60)); // Convert to hours and get the absolute value
      const roundedHoursRemaining = Math.round(hoursRemaining); // Round to the nearest integer

      if (roundedHoursRemaining < numUnits) {
        const customerKey = push(ref(database, `customers/`)).key;
        const newCustomerData = {
          selectedDuration,
          numUnits,
          startingTime: italianTimeFormat(startingTime).toString(),
          endingTime: currentDateTime.toString(),
          licensePlate,
          selectedCity,
          selectedParking,
          price: totalPrice,
          email,
          selectedPaymentMethod,
          paid: "SÌ",
          creditHours: numUnits - roundedHoursRemaining,
        };
        set(ref(database, `customers/${customerKey}`), newCustomerData).catch(
          (error) => {
            console.error("Error adding Customer: ", error);
          }
        );
      } else {
        const newCustomerData = {
          selectedDuration,
          numUnits,
          startingTime: italianTimeFormat(startingTime).toString(),
          endingTime: currentDateTime.toString(),
          licensePlate,
          selectedCity,
          selectedParking,
          price: totalPrice, // Set the price to the calculated total price
          email,
          selectedPaymentMethod,
          paid: "SÌ",
        };
        // Use the generated key to set the new parking entry
        set(ref(database, `customers/${customerKey}`), newCustomerData).catch(
          (error) => {
            console.error("Error adding Customer: ", error);
          }
        );
      }
    } else {
      const newCustomerData = {
        selectedDuration,
        numUnits,
        startingTime: italianTimeFormat(startingTime).toString(),
        endingTime: currentDateTime.toString(),
        licensePlate,
        selectedCity,
        selectedParking,
        price: totalPrice, // Set the price to the calculated total price
        email,
        selectedPaymentMethod,
        paid: "SÌ",
      };
      // Use the generated key to set the new parking entry
      set(ref(database, `customers/${customerKey}`), newCustomerData).catch(
        (error) => {
          console.error("Error adding Customer: ", error);
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setTotalPrice(cost * numUnits);
    setCheckButton(true);
    // All fields are filled, proceed to add customer to Firebase

    // Assuming you have a Firebase reference called 'customers'

    // You can also reset the button state after a delay if needed
    if (selectedPaymentMethod === "creditCard") {
      // After 2 seconds, call handlePaymentSuccess
      setTimeout(() => {
        setIsPaymentOpen(true);
        setCheckButton(false);
      }, 3000);
    }
    if (selectedPaymentMethod === "paypal") {
      setTimeout(() => {
        setPaypalButton(true);
        setCheckButton(false);
      }, 3000);
    }
  };

  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // You can adjust the breakpoint (768) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch the list of cities from Firebase
    const citiesRef = ref(database, "cities");
    get(citiesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const citiesData = snapshot.val();
          // Convert citiesData object to an array of city names
          const cityNames = Object.keys(citiesData);
          setCities(cityNames);
        }
      })
      .catch((error) => {
        console.error("Error fetching cities: ", error);
      });
  }, []);

  useEffect(() => {
    // Fetch parking names for the selected city
    if (selectedCity) {
      const cityRef = ref(database, `cities/${selectedCity}`);
      get(cityRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const cityData = snapshot.val();
            const parkingNames = Object.keys(cityData);
            setParkingOptions(parkingNames);
          }
        })
        .catch((error) => {
          console.error("Error fetching parking names: ", error);
        });
    } else {
      // Reset parking options if no city is selected
      setParkingOptions([]);
    }
  }, [selectedCity]);

  const setHoursFromSlot1StartingTime = (
    slot1StartingTime,
    selectedEndingTime
  ) => {
    const [hours, minutes] = slot1StartingTime.split(":");
    const newDate = new Date();
    newDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);

    // Get the current time
    const currentTime = new Date();

    if (currentTime >= newDate && currentTime <= selectedEndingTime) {
      setStartingTime(currentTime);
    } else {
      setStartingTime(newDate);
    }
  };

  /*useEffect(() => {
    if (selectedParking) {
      const parkingRef = ref(database, `cities/${selectedCity}/${selectedParking}`);
      get(parkingRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const parkingDetails = snapshot.val();
            let selectedEndingTime = new Date();

            if (parkingDetails.slotUnits == 1) {
                const [hours, minutes] = parkingDetails.slot1EndingTime.split(':');
                selectedEndingTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
            } else if (parkingDetails.slotUnits == 2) {
              
              const [hours, minutes] = parkingDetails.slot2EndingTime.split(':');
              selectedEndingTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
            }
            setendingTime(selectedEndingTime);
          }
        })
        .catch((error) => {
          console.error('Error fetching parking details: ', error);
        });
    }
  }, [selectedParking, selectedCity]);*/

  useEffect(() => {
    // Fetch parking details for the selected parking
    if (selectedParking && selectedCity) {
      const cityRef = ref(
        database,
        `cities/${selectedCity}/${selectedParking}`
      );
      get(cityRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const parkingDetails = snapshot.val();
            let selectedEndingTime = new Date();

            if (parkingDetails.slotUnits == 1) {
              const [hours, minutes] =
                parkingDetails.slot1EndingTime.split(":");
              selectedEndingTime.setHours(
                parseInt(hours, 10),
                parseInt(minutes, 10),
                0
              );
            } else if (parkingDetails.slotUnits == 2) {
              const [hours, minutes] =
                parkingDetails.slot2EndingTime.split(":");
              selectedEndingTime.setHours(
                parseInt(hours, 10),
                parseInt(minutes, 10),
                0
              );
            }
            setHoursFromSlot1StartingTime(
              parkingDetails.slot1StartingTime,
              selectedEndingTime
            );
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0);
            // Convert date strings to Date objects
            const dateFormat = "dd/MM/yyyy"; // Date format of the database
            const period1StartTime = parse(
              `${parkingDetails.period1StartingTime} 00:00:00`,
              `${dateFormat} HH:mm:ss`,
              new Date()
            );
            const period1EndTime = parse(
              `${parkingDetails.period1EndingTime} 00:00:00`,
              `${dateFormat} HH:mm:ss`,
              new Date()
            );
            const period2StartTime = parse(
              `${parkingDetails.period2StartingTime} 00:00:00`,
              `${dateFormat} HH:mm:ss`,
              new Date()
            );
            const period2EndTime = parse(
              `${parkingDetails.period2EndingTime} 00:00:00`,
              `${dateFormat} HH:mm:ss`,
              new Date()
            );

            // Function to check if two dates are equal (ignoring time)
            const areDatesEqual = (date1, date2) =>
              date1.getDate() === date2.getDate() &&
              date1.getMonth() === date2.getMonth() &&
              date1.getFullYear() === date2.getFullYear();

            // Check if the current date falls within period 1
            if (
              currentDate >= period1StartTime &&
              (currentDate <= period1EndTime ||
                areDatesEqual(currentDate, period1EndTime))
            ) {
              setCostContain({
                hourly: parkingDetails.period1hourly,
                daily: parkingDetails.period1daily,
              });
              setCost(parkingDetails.period1hourly);
            }
            // Check if the current date falls within period 2
            else if (
              currentDate >= period2StartTime &&
              (currentDate <= period2EndTime ||
                areDatesEqual(currentDate, period2EndTime))
            ) {
              setCostContain({
                hourly: parkingDetails.period2hourly,
                daily: parkingDetails.period2daily,
              });

              setCost(parkingDetails.period2hourly);
            } else {
              // Current date does not fall within either period
              setCostContain({
                hourly: parkingDetails.period2hourly,
                daily: parkingDetails.period2daily,
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching parking details: ", error);
        });
    } else {
      setCostContain({ hourly: 0, daily: 0 });
      setParkingDetails({});
    }
  }, [selectedParking, selectedCity]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = startingTime;

      if (selectedDuration === "ORARIO") {
        const futureDate = new Date(
          currentDate.getTime() + numUnits * 60 * 60 * 1000
        );
        setCurrentDateTime(italianTimeFormat(futureDate));
      } else if (selectedDuration === "GIORNALIERO") {
        if (selectedParking) {
          const cityRef = ref(
            database,
            `cities/${selectedCity}/${selectedParking}`
          );
          get(cityRef)
            .then((snapshot) => {
              if (snapshot.exists()) {
                const parkingDetails = snapshot.val();
                const slotUnits = parkingDetails.slotUnits;
                let futureDate;

                if (slotUnits === 2) {
                  futureDate = new Date(currentDate);
                  futureDate.setHours(
                    parkingDetails.slot2EndingTime.split(":")[0]
                  );
                  futureDate.setMinutes(
                    parkingDetails.slot2EndingTime.split(":")[1]
                  );
                } else {
                  futureDate = new Date(currentDate);
                  futureDate.setHours(
                    parkingDetails.slot1EndingTime.split(":")[0]
                  );
                  futureDate.setMinutes(
                    parkingDetails.slot1EndingTime.split(":")[1]
                  );
                }

                if (futureDate) {
                  console.log(futureDate);
                  setCurrentDateTime(italianTimeFormat(futureDate));
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching parking details:", error.message);
            });
        } else {
          const futureDate = new Date(
            currentDate.getTime() + numUnits * 24 * 60 * 60 * 1000
          );
          setCurrentDateTime(italianTimeFormat(futureDate));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedDuration, numUnits, selectedParking, startingTime]);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {isSmallScreen ? null : (
        <div style={containerStyle} className="left-container">
          <Image className="left-image" />
        </div>
      )}
      <div
        style={isSmallScreen ? fullScreenContainerStyle : rightContainerStyle}
        className="right-container"
      >
        {paypalButton ? (
          // Render the PayPalButton when paypalButton is true
          <PayPalButton
            setPaypalButton={setPaypalButton}
            total={cost * numUnits}
            onSuccess={handlePaymentSuccess}
            selectedCity={selectedCity}
            selectedParking={selectedParking}
            selectedDuration={selectedDuration}
            currentDate={italianTimeFormat(startingTime)}
            numUnits={numUnits}
            futureDate={currentDateTime}
            licensePlate={licensePlate}
            email={email}
            selectedPaymentMethod={selectedPaymentMethod}
            setPaymentMethod={setSelectedPaymentMethod}
            setIsPaymentOpen={setIsPaymentOpen}
            setSelectedCity={setSelectedCity}
            setSelectedParking={setSelectedParking}
            setNumUnits={setNumUnits}
            setSelectedDuration={setSelectedDuration}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            setCurrentDateTime={setCurrentDateTime}
            setPrivacyChecked={setPrivacyChecked}
            setTermsChecked={setTermsChecked}
            setEmail={setEmail}
            setLicensePlate={setLicensePlate}
          />
        ) : isPaymentOpen ? (
          // Render the payment form when isPaymentOpen is true
          <PaymentForm
            total={cost * numUnits}
            onSuccess={handlePaymentSuccess}
            selectedCity={selectedCity}
            selectedParking={selectedParking}
            selectedDuration={selectedDuration}
            currentDate={italianTimeFormat(startingTime)}
            numUnits={numUnits}
            futureDate={currentDateTime}
            licensePlate={licensePlate}
            email={email}
            selectedPaymentMethod={selectedPaymentMethod}
            setPaymentMethod={setSelectedPaymentMethod}
            setIsPaymentOpen={setIsPaymentOpen}
            setSelectedCity={setSelectedCity}
            setSelectedParking={setSelectedParking}
            setNumUnits={setNumUnits}
            setSelectedDuration={setSelectedDuration}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            setCurrentDateTime={setCurrentDateTime}
            setPrivacyChecked={setPrivacyChecked}
            setTermsChecked={setTermsChecked}
            setEmail={setEmail}
            setLicensePlate={setLicensePlate}
          />
        ) : (
          <div
            style={{
              width: "88%",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Logo />
            <div style={headingStyle}>
              ACQUISTA QUI IL TICKET PER LA TUA SOSTA!!
            </div>
            <div style={isSmallScreen ? { width: "95%" } : { width: "85%" }}>
              <HoverPopupDiv popupContent={popupContent} />
            </div>
            <form style={formStyle} onSubmit={handleSubmit}>
              <div style={isSmallScreen ? { width: "95%" } : { width: "85%" }}>
                <div className="input-container">
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    style={selectStyle}
                  >
                    <option value="">-- SELEZIONA UNA CITTÀ --</option>
                    {cities.map((cityName) => (
                      <option key={cityName} value={cityName}>
                        {cityName}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedCity && (
                  <div className="input-container">
                    <select
                      value={selectedParking}
                      onChange={(e) => setSelectedParking(e.target.value)}
                      style={selectStyle}
                      placeholder="EMAIL PER RICEZIONE"
                    >
                      <option value="">-- SELEZIONA UN PARCHEGGIO --</option>
                      {parkingOptions.map((parkingKey) => (
                        <option key={parkingKey} value={parkingKey}>
                          {parkingKey}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div
                style={{
                  ...optionsContainerStyle,
                  width: isSmallScreen ? "95%" : "85%",
                }}
              >
                <div
                  style={{
                    ...optionStyle,
                    backgroundColor:
                      selectedDuration === "ORARIO" ? "#81c349" : "white",
                    color: selectedDuration === "ORARIO" ? "white" : "black",
                  }}
                  onClick={() => {
                    setSelectedDuration("ORARIO");
                    setNumUnits(1);
                    setCost(CostContain.hourly);
                  }}
                >
                  <p style={{ fontSize: 20 }}>
                    {" "}
                    ORARIO <br /> {CostContain.hourly} €
                  </p>
                </div>
                <div
                  style={{
                    ...option2Style,
                    backgroundColor:
                      selectedDuration === "GIORNALIERO"
                        ? "#81c349"
                        : "lightgrey",
                    color:
                      selectedDuration === "GIORNALIERO" ? "white" : "black",
                  }}
                  onClick={() => {
                    setSelectedDuration("GIORNALIERO");
                    setNumUnits(1);
                    setCost(CostContain.daily);
                  }}
                >
                  <p style={{ fontSize: 20 }}>
                    {" "}
                    GIORNALIERO <br />
                    {CostContain.daily} €
                  </p>
                </div>
                {/*<div
                  style={{
                    ...option2Style,
                    backgroundColor:
                      selectedDuration === "SETTIMANALE"
                        ? "#81c349"
                        : "lightgrey",
                    color:
                      selectedDuration === "SETTIMANALE" ? "white" : "black",
                  }}
                  onClick={() => {
                    setSelectedDuration("SETTIMANALE");
                    setNumUnits(1);
                    setCost(parkingDetails.weekly);
                  }}
                >
                  <p style={{ fontSize: 18 }}>
                    {" "}
                    SETTIMANALE <br /> {parkingDetails.weekly} €
                  </p>
                </div>*/}
              </div>
              <div
                style={
                  isSmallScreen
                    ? { width: "95%", borderRadius: 10 }
                    : { width: "85%", borderRadius: 10 }
                }
              >
                <DurationSelector
                  selectedDuration={selectedDuration}
                  numUnits={numUnits}
                  isSmallScreen={isSmallScreen}
                  onIncrement={() =>
                    setNumUnits(
                      Math.min(
                        numUnits + 1,
                        selectedDuration === "ORARIO"
                          ? 23
                          : selectedDuration === "SETTIMANALE"
                          ? 3
                          : 6
                      )
                    )
                  }
                  onDecrement={() => setNumUnits(Math.max(numUnits - 1, 1))}
                  onChange={(e) =>
                    setNumUnits(
                      Math.min(
                        Math.max(parseInt(e.target.value, 10), 1),
                        selectedDuration === "ORARIO"
                          ? 23
                          : selectedDuration === "SETTIMANALE"
                          ? 6
                          : 31
                      )
                    )
                  }
                />
              </div>
              <div
                style={
                  isSmallScreen
                    ? { width: "95%", borderRadius: 10 }
                    : { width: "85%", borderRadius: 10 }
                }
              >
                <CustomInput
                  icon={<FaCalendar />}
                  value={currentDateTime}
                  onChange={(e) => setCurrentDateTime(e.target.value)}
                  placeholder="SELEZIONA DATA"
                />
              </div>
              <div style={isSmallScreen ? { width: "95%" } : { width: "85%" }}>
                <CustomInput
                  icon={<FaCar />}
                  value={licensePlate}
                  onChange={(e) => setLicensePlate(e.target.value)}
                  placeholder="NUMERO DI TARGA"
                />
              </div>
              <div style={isSmallScreen ? { width: "95%" } : { width: "85%" }}>
                <CustomInput
                  icon={<FaEnvelope />}
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="E-MAIL"
                />
                {!isValidEmail && (
                  <p style={{ color: "red" }}>
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <div style={isSmallScreen ? { width: "95%" } : { width: "85%" }}>
                <div style={paymentMethodOptionsStyle}>
                  <div
                    style={{
                      ...paymentOptionStyle,
                      backgroundColor:
                        selectedPaymentMethod === "creditCard"
                          ? "#81c349"
                          : "white",
                      color:
                        selectedPaymentMethod === "creditCard"
                          ? "white"
                          : "black",
                    }}
                    onClick={() => setSelectedPaymentMethod("creditCard")}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaCreditCard
                        style={{ marginRight: "8px", paddingTop: 0 }}
                      />
                      <p style={{ fontSize: 18, fontWeight: "" }}>
                        CARTA DI CREDITO
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      ...paymentOptionStyle,
                      backgroundColor:
                        selectedPaymentMethod === "paypal"
                          ? "#81c349"
                          : "white",
                      color:
                        selectedPaymentMethod === "paypal" ? "white" : "black",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setSelectedPaymentMethod("paypal")}
                  >
                    <FaPaypal style={{ marginRight: "8px", paddingTop: 0 }} />
                    <p style={{ fontSize: 18, fontWeight: "" }}>PAYPAL</p>
                  </div>
                </div>
                {/* Toggle for Terms and Conditions */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 30,
                  }}
                >
                  <Switch
                    onChange={(checked) => setTermsChecked(checked)}
                    checked={termsChecked}
                    height={20} // Adjust the height as needed for a smaller toggle
                    width={40} // Adjust the width as needed for a smaller toggle
                  />
                  <p style={{ marginLeft: "10px" }}>
                    La presentazione di questo modulo costituisce l'accettazione
                    dei nostri termini e condizioni, comprese le pertinenti
                    clausole di esclusione e politiche di utilizzo dei dati.
                  </p>
                </div>

                {/* Toggle for Privacy Policy */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Switch
                    onChange={(checked) => setPrivacyChecked(checked)}
                    checked={privacyChecked}
                    height={20} // Adjust the height as needed for a smaller toggle
                    width={40} // Adjust the width as needed for a smaller toggle
                  />
                  <label style={{ marginLeft: "10px" }}>
                    Invio di questo modulo implica l'accettazione dei termini
                    d'uso e della politica sulla privacy del nostro sito web
                  </label>
                </div>
              </div>
              <input
                type="hidden"
                name="paymentMethod"
                value={selectedPaymentMethod}
              />
              <div
                style={
                  isSmallScreen
                    ? {
                        width: "95%",
                        display: "flex",
                        justifyContent: "center",
                      }
                    : { width: "85%", display: "flex" }
                }
              >
                {" "}
                <button
                  type="submit"
                  style={{
                    ...submitButton,
                    backgroundColor:
                      termsChecked &&
                      privacyChecked &&
                      selectedCity &&
                      selectedParking &&
                      licensePlate &&
                      email &&
                      selectedPaymentMethod &&
                      isValidEmail
                        ? "#81c349" // Green color when all conditions are met
                        : "#808080", // Gray color when not all conditions are met
                  }}
                  onClick={handleSubmit}
                  disabled={
                    !termsChecked ||
                    !privacyChecked ||
                    !selectedCity ||
                    !selectedParking ||
                    !licensePlate ||
                    !email ||
                    !selectedPaymentMethod ||
                    !isValidEmail
                  }
                >
                  {checkButton
                    ? "Attendere il caricamento..."
                    : `PAGHI ${selectedParking ? cost * numUnits : ""} €`}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

const selectStyle = {
  width: "100%",
  borderRadius: "10px",
  border: "0px",
  height: "50px",
  paddingLeft: "10px",
  fontSize: 18,
  marginTop: 20,
};
const fullScreenContainerStyle = {
  width: "100%",
  backgroundColor: "#ebf3ff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const containerStyle = {
  width: "50%",
  backgroundColor: "lightblue",
  float: "left",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const rightContainerStyle = {
  width: "50%",
  backgroundColor: "#ebf3ff",
  float: "right",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const headingStyle = {
  padding: 10,
  fontSize: "22px",
  fontWeight: "bold",
  color: "#384b57",
  marginBottom: "10px",
  marginTop: "0px",
  textAlign: "center",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
};

const optionsContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "",
  marginTop: "20px",
  marginBottom: "10px",
};

const optionStyle = {
  flex: 1,
  padding: "5px 10px",
  cursor: "pointer",
  border: "0px solid green",
  backgroundColor: "white",
  color: "black",
  borderRadius: "10px",
  textAlign: "center",
  height: 40,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: 5,
};
const option2Style = {
  flex: 1,
  padding: "5px 10px",
  cursor: "pointer",
  border: "0px solid green",
  backgroundColor: "white",
  color: "black",
  borderRadius: "10px",
  textAlign: "center",
  height: 40,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: 5,
};

const paymentMethodOptionsStyle = {
  display: "flex",
  justifyContent: "center",
  width: "",
  marginTop: "5px",
  marginBottom: "20px",
  height: 50,
  boxSizing: "border-box",
};

const paymentOptionStyle = {
  flex: 1,
  padding: "8px",
  cursor: "pointer",
  border: "0px solid green",
  backgroundColor: "white",
  color: "black",
  borderRadius: "10px",
  textAlign: "center",
  height: 40,
  margin: 5,
  display: "flex", // Add display: flex
  justifyContent: "center",
  alignItems: "center", // Center vertically
};

const submitButton = {
  color: "white",
  fontWeight: "bold",
  backgroundColor: "#3b4247",
  marginTop: "5%",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  marginBottom: 20,
  fontSize: 20,
  cursor: "pointer",
  border: "none",
};

export default CustomerHome;
