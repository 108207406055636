import React, { useState, useEffect } from "react";
import { ref, set, database } from "../config/firebase";
import { Link } from "react-router-dom";

function AddCity() {
  const [cityName, setCityName] = useState("");
  const [isCityAdded, setIsCityAdded] = useState(false);
  const [error, setError] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800); // You can adjust the breakpoint (800) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    width: isSmallScreen ? "90%" : "500px",
    margin: "0 auto",
    marginTop: 20,
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#f7f7f7",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
    fontSize: "18px",
    textAlign: "center",
    marginTop: isSmallScreen ? 0 : 20
  };

  const headingStyle = {
    fontSize: "24px",
    marginBottom: "20px",
    textAlign: "center",
    
    color:'#81c57a'
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const inputContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };

  const inputStyle = {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px",
    width: "90%",
    marginBottom: "10px",
    fontSize: 20,
    backgroundColor: "#ffffff",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    
    fontSize:20,
    fontWeight:'bold',
  };

  const successMessageStyle = {
    marginTop: "10px",
    backgroundColor: "#4caf50",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  };

  const errorStyle = {
    marginTop: "10px",
    color: "red",
  };

  const handleAddCity = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (cityName.trim() === "") {
      setError("Il nome della città non può essere vuoto");
      return;
    }

    // Verify that your Firebase database reference points to the correct location
    const cityRef = ref(database, "cities/" + cityName);

    // Attempt to write data to the database
    set(cityRef, true)
      .then(() => {
        setIsCityAdded(true);
        setError(""); // Clear error message
        setTimeout(() => {
          setCityName(""); // Clear the input field
          setIsCityAdded(false);
        }, 3000); // Clear success message after 2 seconds
      })
      .catch((error) => {
        console.error("Error adding city: ", error);
      });
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>AGGIUNGI CITTÀ</h2>
      <form style={formStyle} onSubmit={handleAddCity}>
        <div style={inputContainerStyle}>
          {error && <div style={errorStyle}>{error}</div>}
          <input
            type="text"
            placeholder="Inserisci il nome della città"
            value={cityName}
            onChange={(e) => {
              setCityName(e.target.value);
              setError(""); // Clear error message when user starts typing
            }}
            style={inputStyle}
          />
          <button type="submit" style={buttonStyle}>
          Aggiungere
          </button>
        </div>
      </form>

      {isCityAdded && (
        <div style={successMessageStyle}>
          <p>Città "{cityName}" aggiunto con successo!</p>
        </div>
      )}
    </div>
  );
}

export default AddCity;
