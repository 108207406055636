import React, { useState, useEffect } from "react";
import {
  auth,
  database,
  createUserWithEmailAndPassword,
  ref,
  set,
  get,
  onValue,
  update,
} from "../config/firebase";
import { Link } from "react-router-dom";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedParking, setSelectedParking] = useState("");
  const [cityNames, setCityNames] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [parkingOptions, setParkingOptions] = useState([]);
  const [parkingNames, setParkingNames] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800); // You can adjust the breakpoint (800) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    width: isSmallScreen ? '80%' : "500px",
    margin: "0 auto",
    
    
    marginTop: 20,
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#f7f7f7",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
    fontSize: "18px",
    textAlign: "center",
    marginTop: isSmallScreen ? 0 : 20
  };

  const headingStyle = {
    fontSize: "24px",
    marginBottom: "20px",
    textAlign: "center",
    color: "#81c57a",
  };

  const inputStyle = {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px",
    width: "90%",
    marginBottom: "10px",
    fontSize: 20,
    backgroundColor: "#ffffff",
  };

  const buttonStyle = {
    padding: "5px 10px",
    marginRight: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    width: 100,
    height: 40,
    fontSize: 15,
    margin: 5,
    borderRadius: 20,
  };

  const successMessageStyle = {
    marginTop: "10px",
    backgroundColor: "#4caf50",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  };

  const errorMessageStyle = {
    color: "red",
    fontSize: "18px",
  };

  const buttonHoverStyle = {
    backgroundColor: "darkblue",
  };

  const fetchUsers = () => {
    const usersRef = ref(database, "users");

    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      if (usersData) {
        const userList = Object.keys(usersData).map((key) => ({
          key,
          ...usersData[key],
        }));
        setUsers(userList);
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // Fetch the list of cities from Firebase
    const citiesRef = ref(database, "cities");
    get(citiesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const citiesData = snapshot.val();
          // Convert citiesData object to an array of city names
          const cityNames = Object.keys(citiesData);
          setCityNames(cityNames);
          setSelectedCity(cityNames[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching cities: ", error);
      });
  }, []);

  useEffect(() => {
    // Fetch parking names for the selected city
    if (selectedCity) {
      const cityRef = ref(database, `cities/${selectedCity}`);
      get(cityRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const cityData = snapshot.val();
            const parkingNames = Object.keys(cityData);
            setParkingNames(parkingNames);
            setSelectedParking(parkingNames[0]);
          }
        })
        .catch((error) => {
          console.error("Error fetching parking names: ", error);
        });
    } else {
      // Reset parking options if no city is selected
      setParkingOptions([]);
    }
  }, [selectedCity]);

  const handleCityChange = (city) => {
    setSelectedCity(city);
    // Fetch parking names for the selected city from Firebase
    const parkingNamesForCity = citiesData[city];
    setParkingNames(parkingNamesForCity);
  };

  const handleEditUser = (user) => {
    // Set the user data for editing, including "alottedCity" and "alottedParking"
    setEditingUser({
      ...user,
      alottedCity: user.alottedCity, // Add the current alottedCity value
      alottedParking: user.alottedParking, // Add the current alottedParking value
    });
  };
  const handleUpdateUser = async () => {
    try {
      if (editingUser) {
        // Update user data in Firebase
        const userId = editingUser.key;
        await update(ref(database, `users/${userId}`), {
          fullName: editingUser.fullName,
          email: editingUser.email,
          alottedCity: selectedCity,
          alottedParking: selectedParking,
          userType:'valet'
        });

        // Clear the editing user state
        setEditingUser(null);

        // Show success message
        setSuccessMessage("Utente aggiornato con successo");

        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);

        fetchUsers();
        // Clear error message if any
        setError(null);
      }
    } catch (error) {
      setError("Errore durante l'aggiornamento dell'utente");
      setSuccessMessage(null);
      console.error(error.message);
    }
  };

  const handleSignup = async () => {
    try {
      // Create the user account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Store user data in Realtime Database
      const userId = userCredential.user.uid;
      await set(ref(database, `users/${userId}`), {
        fullName,
        email,
        userType: "valet",
        alottedCity: selectedCity,
        alottedParking: selectedParking,
      });

      // Show success message
      setSuccessMessage("Il parcheggiatore è stato aggiunto con successo");

      // Clear email, password, and full name fields
      setEmail("");
      setPassword("");
      setFullName("");

      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      fetchUsers();
      // Clear error message if any
      setError(null);
    } catch (error) {
      setError("Inserisci un'e-mail e una password valide");
      setSuccessMessage(null);
      console.error(error.message);
    }
  };

  const valetUsers = users.filter((user) => user.userType === "valet");

  const deleteUser = async (userId) => {
    const confirmed = window.confirm(
      "Sei sicuro di voler eliminare questo parcheggiatore?"
    );
    if (confirmed) {
      try {
        // Remove the user from the database
        await set(ref(database, `users/${userId}`), null);
        // Show success message
        setSuccessMessage("Il parcheggiatore è stato eliminato con successo");
        // Refresh the user list
        fetchUsers();
      } catch (error) {
        setError(
          "Si è verificato un errore durante l'eliminazione del parcheggiatore"
        );
        console.error(error.message);
      }
    }
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>AGGIUNGI PARCHEGGIATORE</h2>
      <div>
        {error && <p style={errorMessageStyle}>{error}</p>}
        <input
          type="text"
          placeholder="Nome e cognome"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          style={inputStyle}
        />
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Parola d'ordine"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        {/* City Dropdown */}
        <select
          style={inputStyle}
          value={selectedCity}
          onChange={(e) => handleCityChange(e.target.value)}
        >
          {cityNames.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
        {
          <select
            style={inputStyle}
            value={selectedParking}
            onChange={(e) => setSelectedParking(e.target.value)}
          >
            {Array.isArray(parkingNames) ? (
              parkingNames.map((parking) => (
                <option key={parking} value={parking}>
                  {parking}
                </option>
              ))
            ) : (
              <option value="">No Parking Available</option>
            )}
          </select>
        }

        {/* Display error message if there's an error */}
        {/* Display success message if sign-up was successful */}
        {successMessage && <p style={successMessageStyle}>{successMessage}</p>}

        <button
          disabled={!email || !password || !selectedCity || !selectedParking}
          style={{
            ...buttonStyle,
            ...(successMessage ? buttonHoverStyle : {}),
          }}
          onClick={handleSignup}
        >
          Aggiungere
        </button>
      </div>

      <div>
        <h2>PARCHEGGIATORI ESISTENTI</h2>
        <ul style={{ listStyleType: "none", padding: 0 }}>
  {valetUsers.map((user) => (
    <li
      key={user.key}
      style={{
        border: "1px solid #ccc",
        borderRadius: "10px",
        margin: "10px 0",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div style={{ display: "flex", flexDirection:'column', justifyContent: "", width:"100%"}}>
          <div>
            <p style={{margin:0, marginTop: 3, padding: 0 }}>
              <strong>Nome e cognome:</strong> {user.fullName}
            </p>
            <p style={{ margin:0,marginTop: 3, padding: 0 }}>
              <strong>E-mail:</strong> {user.email}
            </p>
            <p style={{margin:0, marginTop: 3, padding: 0 }}>
              <strong>Alotted City:</strong> {user.alottedCity}
            </p>
            <p style={{ margin:0,marginTop: 3, padding: 0 }}>
              <strong>Alotted Parking:</strong> {user.alottedParking}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 20, marginRight: 20, width:isSmallScreen?'':'70%', margin:'auto', marginTop:5 }}>
            <button
              style={{
                ...buttonStyle,
                backgroundColor: "blue",
                marginBottom: "5px", // Add margin between buttons
              }}
              onClick={() => handleEditUser(user)} // Open edit form
            >
              Edit
            </button>
            <button
              style={{
                ...buttonStyle,
                backgroundColor: "#dc3545",
                marginBottom: "5px", // Add margin between buttons
              }}
              onClick={() => deleteUser(user.key)}
            >
              Eliminare
            </button>
          </div>
        </div>
        {editingUser &&
        editingUser.key === user.key && ( // Render the edit form only if editingUser is not null and matches the current user
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "10px",
                margin: "10px 0",
                padding: "10px",
              }}
            >
              <h2 style={headingStyle}>MODIFICA UTENTE</h2>
              <div>
                {error && <p style={errorMessageStyle}>{error}</p>}
                <input
                  type="text"
                  placeholder="Nome e cognome"
                  value={editingUser.fullName}
                  onChange={(e) =>
                    setEditingUser({
                      ...editingUser,
                      fullName: e.target.value,
                    })
                  }
                  style={inputStyle}
                />
                <select
                  style={inputStyle}
                  value={editingUser.alottedCity}
                  onChange={(e) => {
                    setEditingUser({
                      ...editingUser,
                      alottedCity: e.target.value,
                    });
                    setSelectedCity(e.target.value);
                  }}
                >
                  {cityNames.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <select
                  style={inputStyle}
                  value={editingUser.alottedParking}
                  onChange={(e) => {
                    setEditingUser({
                      ...editingUser,
                      alottedParking: e.target.value,
                    });
                    setSelectedParking(e.target.value);
                  }}
                >
                  {parkingNames.map((parking) => (
                    <option key={parking} value={parking}>
                      {parking}
                    </option>
                  ))}
                </select>

                <button
                  disabled={
                    !editingUser.email ||
                    !selectedCity ||
                    !selectedParking
                  }
                  style={{
                    ...buttonStyle,
                    ...(successMessage ? buttonHoverStyle : {}),
                  }}
                  onClick={handleUpdateUser}
                >
                  Aggiornare
                </button>
                <button
                  style={{
                    ...buttonStyle,
                    backgroundColor: "red",
                  }}
                  onClick={() => setEditingUser(null)}
                >
                  Chiudere
                </button>
              </div>
            </div>
          )}
      </div>
    </li>
  ))}
</ul>
      </div>
    </div>
  );
}

export default Signup;
