import React, { useState, useEffect } from "react";
import { ref, get, database, set } from "../config/firebase";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker"; // Import the date picker library
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function AddParking() {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [parkingName, setParkingName] = useState("");
  const [isParkingAdded, setIsParkingAdded] = useState(false);
  const [hourlyCost1, setHourlyCost1] = useState("");
  const [dailyCost1, setDailyCost1] = useState("");
  const [hourlyCost2, setHourlyCost2] = useState("");
  const [dailyCost2, setDailyCost2] = useState("");
  const [period1StartingTime, setPeriod1StartingTime] = useState(null);
  const [period1EndingTime, setPeriod1EndingTime] = useState(null);
  const [period2StartingTime, setPeriod2StartingTime] = useState(null);
  const [period2EndingTime, setPeriod2EndingTime] = useState(null);
  const [numberOfSlots, setNumberOfSlots] = useState(1); // Add state for number of slots
  const [slot1StartingTime, setSlot1StartingTime] = useState(null);
  const [slot1EndingTime, setSlot1EndingTime] = useState(null);
  const [slot2StartingTime, setSlot2StartingTime] = useState(null);
  const [slot2EndingTime, setSlot2EndingTime] = useState(null);
  // Error state variables
  const [cityError, setCityError] = useState("");
  const [nameError, setNameError] = useState("");
  const [costError, setCostError] = useState("");
  const [dateError, setDateError] = useState("");
  const [slotError, setSlotError] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800); // You can adjust the breakpoint (800) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Define constant variables for styling
  const containerStyle = {
    width: isSmallScreen ? "90%" : "500px",
    margin: "0 auto", // Center horizontally
    marginTop: 20,
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#f7f7f7",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
    fontSize: "18px",
    textAlign: "center",
    position: "relative", // Relative positioning for success message
    marginTop: isSmallScreen ? 0 : 20
  };

  const headingStyle = {
    fontSize: "24px",

    color: "#81c57a",
    marginBottom: "20px",
    textAlign: "center",
  };

  const selectStyle = {
    width: "95%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px", // Rounded corners for select
    marginBottom: "10px",
    fontSize: 20,
  };

  const inputStyle = {
    width: "90%", // Set input width to 100% to match the select
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px", // Rounded corners for input
    marginBottom: "10px",
    fontSize: 20,
    backgroundColor: "#fff",
    WebkitAppearance: "none",
  };
  const input2Style = {
    width: "90%", // Set input width to 100% to match the select
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px", // Rounded corners for input
    marginBottom: "15px",
    fontSize: 20,
    backgroundColor: "#fff",
    WebkitAppearance: "none",
  };

  const errorStyle = {
    color: "red",
    marginBottom: "5px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: 20,
    fontWeight: "bold",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    display: "block", // Display the button as a block-level element
    margin: "0 auto", // Center the button horizontally
  };
  const successMessageStyle = {
    marginTop: "5px",
    backgroundColor: "#4caf50",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  };
  const periodTimeLabelStyle = {
    fontSize: "18px",
    color: "#333", // You can adjust the color as needed
    marginBottom: "0px",
    marginTop: "0px",
    marginRight: 10,
    margin: 5,
  };

  useEffect(() => {
    // Fetch the list of cities from Firebase Realtime Database
    const citiesRef = ref(database, "cities");

    get(citiesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          // Convert the snapshot to an array of city names
          const cityNames = Object.keys(snapshot.val());
          setCities(cityNames);
        }
      })
      .catch((error) => {
        console.error("Error fetching cities: ", error);
      });
  }, []);

  const handleAddParking = () => {
    // Reset error messages
    setCityError("");
    setNameError("");
    setCostError("");

    // Perform data validation
    let hasError = false;

    if (!selectedCity) {
      setCityError("Seleziona una città.");
      hasError = true;
    }

    if (!parkingName) {
      setNameError("Si prega di fornire il nome del parcheggio.");
      hasError = true;
    }

    if (!hourlyCost1 || !dailyCost1 || !hourlyCost2 || !dailyCost2  ) {
      setCostError("Si prega di fornire i costi per tutti i periodi di tempo.");
      hasError = true;
    }

    if (!period1StartingTime || !period1EndingTime || !period2StartingTime || !period2EndingTime ) {
      // Check if period starting time and ending time are selected
      setDateError("Seleziona l'orario di inizio e di fine del periodo.");
      hasError = true;
    }
    if (numberOfSlots === 1 && (!slot1StartingTime || !slot1EndingTime)) {
      setSlotError(
        "Seleziona l'orario di inizio e di fine del periodo per Slot 1."
      );
      hasError = true;
    }

    if (numberOfSlots === 2) {
      if (!slot1StartingTime || !slot1EndingTime) {
        setSlotError(
          "Seleziona l'orario di inizio e di fine del periodo per Slot 1."
        );
        hasError = true;
      }
      if (!slot2StartingTime || !slot2EndingTime) {
        setSlotError(
          "Seleziona l'orario di inizio e di fine del periodo per Slot 2."
        );
        hasError = true;
      }
    }

    if (hasError) {
      // If there are validation errors, don't proceed with adding parking
      return;
    }
    const formattedP1StartingTime = format(period1StartingTime, "dd/MM/yyyy");
    const formattedP1EndingTime = format(period1EndingTime, "dd/MM/yyyy");
    const formattedP2StartingTime = format(period2StartingTime, "dd/MM/yyyy");
    const formattedP2EndingTime = format(period2EndingTime, "dd/MM/yyyy");
    // Create an object with parking details
    const parkingDetails = {
      period1hourly: hourlyCost1,
      period1daily: dailyCost1,
      period2hourly: hourlyCost2,
      period2daily: dailyCost2,
      period1StartingTime: formattedP1StartingTime, // Store timestamps
      period1EndingTime: formattedP1EndingTime, // Store timestamps
      period2StartingTime: formattedP2StartingTime, // Store timestamps
      period2EndingTime: formattedP2EndingTime, // Store timestamps
    };
    if (numberOfSlots === 1) {
      parkingDetails.slotUnits = 1
      parkingDetails.slot1StartingTime = format(slot1StartingTime, "HH:mm");
      parkingDetails.slot1EndingTime = format(slot1EndingTime, "HH:mm");
    }
    if (numberOfSlots === 2) {
      parkingDetails.slotUnits = 2
      parkingDetails.slot1StartingTime = format(slot1StartingTime, "HH:mm");
      parkingDetails.slot1EndingTime = format(slot1EndingTime, "HH:mm");
      parkingDetails.slot2StartingTime = format(slot2StartingTime, "HH:mm");
      parkingDetails.slot2EndingTime = format(slot2EndingTime, "HH:mm");
    }
    // Use the selectedCity and parkingName to set the new parking entry
    set(ref(database, `cities/${selectedCity}/${parkingName}`), parkingDetails)
      .then(() => {
        setIsParkingAdded(true);

        // Clear input fields and date pickers after 2 seconds
        setTimeout(() => {
          setSelectedCity("");
          setParkingName("");
          setHourlyCost1("");
          setDailyCost1("");
          setHourlyCost2("");
          setDailyCost2("");
          setDateError("");
          setSlotError("");
          setPeriod1StartingTime(null);
          setPeriod1EndingTime(null);
          setPeriod2StartingTime(null);
          setPeriod2EndingTime(null);
          setIsParkingAdded(false);
          setNumberOfSlots(1); // Reset to 1 slot
          setSlot1StartingTime(null);
          setSlot1EndingTime(null);
          setSlot2StartingTime(null);
          setSlot2EndingTime(null);
          setIsParkingAdded(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error adding parking: ", error);
      });
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>AGGIUNGI PARCHEGGIO</h2>
      <select
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
        style={selectStyle}
      >
        <option value="">Seleziona una città</option>
        {cities.map((city) => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>
      <div style={errorStyle}>{cityError}</div>

      {selectedCity && (
        <>
          <input
            type="text"
            placeholder="Nome del parcheggio"
            value={parkingName}
            onChange={(e) => setParkingName(e.target.value)}
            style={inputStyle}
          />
          <div style={errorStyle}>{nameError}</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
              justifyContent: "center",
            }}
          >
            <p style={periodTimeLabelStyle}>Inizio primo periodo 1: </p>
            <DatePicker
              selected={period1StartingTime}
              onChange={(date) => setPeriod1StartingTime(date)}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="d/MM/yyyy"
              placeholderText="Data inizio primo periodo 1"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
              justifyContent: "center",
            }}
          >
            <p style={periodTimeLabelStyle}> Fine primo periodo 1 : </p>
            <DatePicker
              selected={period1EndingTime}
              onChange={(date) => setPeriod1EndingTime(date)}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="d/MM/yyyy"
              placeholderText="Data fine primo periodo 1"
              style={{}}
            />
          </div>
          <div style={errorStyle}>{dateError}</div>
          <input
            type="text"
            placeholder="Costo orario"
            value={hourlyCost1}
            onChange={(e) => setHourlyCost1(e.target.value)}
            style={input2Style}
          />
          <input
            type="text"
            placeholder="Costo giornaliero"
            value={dailyCost1}
            onChange={(e) => setDailyCost1(e.target.value)}
            style={input2Style}
          />
          <div style={errorStyle}>{costError}</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
              justifyContent: "center",
            }}
          >
            <p style={periodTimeLabelStyle}>Inizio primo periodo 2: </p>
            <DatePicker
              selected={period2StartingTime}
              onChange={(date) => setPeriod2StartingTime(date)}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="d/MM/yyyy"
              placeholderText="Data inizio primo periodo 2"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
              justifyContent: "center",
            }}
          >
            <p style={periodTimeLabelStyle}> Fine primo periodo 2 : </p>
            <DatePicker
              selected={period2EndingTime}
              onChange={(date) => setPeriod2EndingTime(date)}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="d/MM/yyyy"
              placeholderText="Data fine primo periodo 2"
              style={{}}
            />
          </div>
          <div style={errorStyle}>{dateError}</div>
          <input
            type="text"
            placeholder="Costo orario"
            value={hourlyCost2}
            onChange={(e) => setHourlyCost2(e.target.value)}
            style={input2Style}
          />
          <input
            type="text"
            placeholder="Costo giornaliero"
            value={dailyCost2}
            onChange={(e) => setDailyCost2(e.target.value)}
            style={input2Style}
          />
          <div style={errorStyle}>{costError}</div>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          >
            <label style={{ margin: 5, marginBottom: 20 }}>
            Numero fasce orarie:
            </label>
            <select
              value={numberOfSlots}
              onChange={(e) => setNumberOfSlots(parseInt(e.target.value, 10))}
              style={{ ...selectStyle, width: "60%" }}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
          {numberOfSlots === 1 && (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={periodTimeLabelStyle}>Inizio fascia oraria 1:</p>
                <DatePicker
                  selected={slot1StartingTime}
                  onChange={(date) => setSlot1StartingTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  placeholderText="Inizio fascia oraria 1"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={periodTimeLabelStyle}>Fine fascia oraria 1:</p>
                <DatePicker
                  selected={slot1EndingTime}
                  onChange={(date) => setSlot1EndingTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  placeholderText="Fine fascia oraria 1"
                />
              </div>
              <div style={errorStyle}>{slotError}</div>
            </div>
          )}
          {numberOfSlots === 2 && (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={periodTimeLabelStyle}>Inizio fascia oraria 1:</p>
                <DatePicker
                  selected={slot1StartingTime}
                  onChange={(date) => setSlot1StartingTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  placeholderText="Inizio fascia oraria 1"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={periodTimeLabelStyle}>Inizio fascia oraria 1:</p>
                <DatePicker
                  selected={slot1EndingTime}
                  onChange={(date) => setSlot1EndingTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  placeholderText="Inizio fascia oraria 1"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={periodTimeLabelStyle}>Inizio fascia oraria 2:</p>
                <DatePicker
                  selected={slot2StartingTime}
                  onChange={(date) => setSlot2StartingTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  placeholderText="Inizio fascia oraria 2"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={periodTimeLabelStyle}>Inizio fascia oraria 2:</p>
                <DatePicker
                  selected={slot2EndingTime}
                  onChange={(date) => setSlot2EndingTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  placeholderText="Inizio fascia oraria 2"
                />
              </div>
              <div style={errorStyle}>{slotError}</div>
            </div>
          )}
        </>
      )}

      <button onClick={handleAddParking} style={buttonStyle}>
        Aggiungere
      </button>

      {isParkingAdded && (
        <div style={successMessageStyle}>
          <p>
            Parcheggio "{parkingName}" aggiunto con successo in "{selectedCity}
            "!
          </p>
        </div>
      )}
    </div>
  );
}

export default AddParking;
