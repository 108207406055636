import React, { useState, useEffect } from 'react';
import { ref, set, get, database, update, remove } from '../config/firebase';

const EditCity = () => {
  const [cities, setCities] = useState([]);
  const [cityName, setCityName] = useState('');
  const [selectedCity, setSelectedCity] = useState(null); // Track the selected city

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800); // You can adjust the breakpoint (800) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // Fetch the list of cities from Firebase
    const citiesRef = ref(database, 'cities');
    get(citiesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const citiesData = snapshot.val();
          // Convert citiesData object to an array of city names
          const cityNames = Object.keys(citiesData);
          setCities(cityNames);
        }
      })
      .catch((error) => {
        console.error('Error fetching cities: ', error);
      });
  }, []);

  const updatecities = () => {
    const citiesRef = ref(database, 'cities');
    get(citiesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const citiesData = snapshot.val();
          // Convert citiesData object to an array of city names
          const cityNames = Object.keys(citiesData);
          setCities(cityNames);
        }
      })
      .catch((error) => {
        console.error('Error fetching cities: ', error);
      });
  };

  const handleEdit = (name) => {
    setCityName(name);
    setSelectedCity(name); // Set the selected city when clicking "Edit"
  };

  const handleUpdate = () => {
    if (cityName && selectedCity) {
      const cityRef = ref(database, `cities/${selectedCity}`);
  
      // Fetch the data of the selected city
      get(cityRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const cityData = snapshot.val();
  
            // Check if the city name has changed
            if (selectedCity !== cityName) {
              const newCityRef = ref(database, `cities/${cityName}`);
  
              // Set the data with the new city name as the key
              set(newCityRef, cityData)
                .then(() => {
                  // Remove the old city name
                  remove(cityRef)
                    .then(() => {
                      setCityName('');
                      setSelectedCity(null); // Clear selected city after update
                      updatecities();
                    })
                    .catch((error) => {
                      console.error('Error removing old city name: ', error);
                    });
                })
                .catch((error) => {
                  console.error('Error setting new city name: ', error);
                });
            } else {
              // City name hasn't changed, clear the input field and selected city
              setCityName('');
              setSelectedCity(null); // Clear selected city after update
              updatecities();
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching selected city: ', error);
        });
    }
  };
  
  const handleDelete = (name) => {
    if (window.confirm('Sei sicuro di voler eliminare questa città?')) {
      const cityRef = ref(database, `cities/${name}`);
      remove(cityRef)
        .then(() => {
          setCityName('');
          setSelectedCity(null); // Clear selected city after delete
          updatecities();
        })
        .catch((error) => {
          console.error('Error deleting city: ', error);
        });
    }
  };

  const handleCancel = () => {
    setCityName('');
    setSelectedCity(null);
  };

  // Define styles using const variables
  const containerStyle = {
    width: isSmallScreen ? "90%" : 600,
    margin:  '20px auto',
    padding: '20px', // Add padding
    backgroundColor: '#f7f7f7', // Background color
    borderRadius: '10px', // Rounded corners
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.1)', // Box shadow
    marginTop: isSmallScreen ? 0 : 20
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '10px',
    color:'#81c57a'
  };

  const headingCenterStyle = {
    textAlign: 'center', // Center align the heading text
  };

  const listStyle = {
    listStyle: 'none',
    padding: '0',
    margin: '20px 0 0 0', // Adjust margin
  };

  const cityItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: isSmallScreen ? 10 : '50px', // Adjust margin
    marginRight: isSmallScreen ? 10 :'50px', // Adjust margin
    marginBottom: 5,
  };

  const citySpanStyle = {
    flex: '1',
    fontSize: '25px',
    fontWeight: 'bold',
  };

  const buttonContainerStyle = {
    marginLeft: '10px',
  };

  const buttonStyle = {
    padding: '5px 10px',
    marginRight: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    width: isSmallScreen ? 80 : '95px', // Adjust width
    height: '40px',
    fontSize: '15px',
    margin: '10px 5px', // Adjust margin
    borderRadius: '20px',
  };

  const deleteButtonStyle = {
    padding: '5px 10px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    height: '40px',
    width: '80px', // Adjust width
    fontSize: '15px',
    borderRadius: '20px',
  };

  const editInputStyle = {
    width:  isSmallScreen ? 100 :'200px',
    marginLeft: isSmallScreen ? 10 :  '50px', // Adjust margin
    marginRight: '5px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '20px', // Rounded corners for input
    marginBottom: '10px',
    fontSize: '20px',
    backgroundColor: '#fff',
  };

  // Media query for small screens
  const smallScreenStyle = {
    '@media (max-width: 768px)': {
      cityItemStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      buttonContainerStyle: {
        marginLeft: '0',
        marginTop: '10px',
      },
    },
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ ...headingStyle, ...headingCenterStyle }}>MODIFICARE I NOMI DELLE CITTÀ</h1>
      <ul style={listStyle}>
        {cities.map((city) => (
          <li key={city}>
            <div style={{ ...cityItemStyle, ...smallScreenStyle.cityItemStyle }}>
              <span style={citySpanStyle}>{city}</span>
              <div style={{ ...buttonContainerStyle, ...smallScreenStyle.buttonContainerStyle }}>
                <button style={buttonStyle} onClick={() => handleEdit(city)}>
                Modificare
                </button>
                <button style={deleteButtonStyle} onClick={() => handleDelete(city)}>
                Eliminare
                </button>
              </div>
            </div>
            {selectedCity === city && (
              <div className="edit-city-form" style={{ marginTop: '10px' }}>
                <input
                  style={editInputStyle}
                  type="text"
                  placeholder="Modifica nome città"
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                />
                <button style={buttonStyle} onClick={handleUpdate}>
                Aggiorname
                </button>
                <button style={buttonStyle} onClick={handleCancel}>
                Vicino
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EditCity;
