import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function PayPalButton({
  total,
  onSuccess,
  selectedCity,
  selectedParking,
  selectedDuration,
  currentDate,
  numUnits,
  futureDate,
  licensePlate,
  setPaypalButton,
  email,
  selectedPaymentMethod,
  setSelectedCity,
  setSelectedParking,
  setSelectedPaymentMethod,
  setNumUnits,
  setCurrentDateTime,
  setSelectedDuration,
  setPrivacyChecked,
  setTermsChecked,
  setEmail,
  setLicensePlate,
}) {
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  const headingStyle = {
    fontSize: "26px",
    marginBottom: "20px",
    color: "grey",
    fontWeight: "bold",
  };
  const iconStyle = {
    marginRight: "8px", // Adjust the margin as needed for spacing between the icon and text
    fontSize: "12px", // Adjust the icon size as needed
  };

  const userInfoStyleContainer = {
    margin: "0 auto",
    width: "90%",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    backgroundColor: "white", // Add white background
    border: "1px solid #ccc", // Add 1px border
    borderRadius: "10px", // Add border radius for rounded corners
    padding: "10px", // Add padding for spacing
  };
  const userInfoStyle = {
    fontSize: "15px",
    margin: 0,
  };
  const buttonStyle = {
    alignSelf: "center",
    backgroundColor: "#4CAF50",
    color: "white",
    fontSize: "18px",
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    borderRadius: 10,
    width: "60%",
    marginTop: 20,
  };
  const totalPriceStyle = {
    fontSize: "15px",
    margin: 0,
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2 style={headingStyle}>PAGA CON PAYPAL</h2>
      {paymentSuccessful ? (
        // Display user information when payment is successful
        <div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>🏙️</span>
            <p style={userInfoStyle}>
            Città: <strong>{selectedCity}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>🚗</span>
            <p style={userInfoStyle}>
            Parcheggio: <strong>{selectedParking}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>⏳</span>
            <p style={userInfoStyle}>
            Durata: <strong>{selectedDuration}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>#️⃣</span>
            <p style={userInfoStyle}>
              Qta: <strong>{numUnits}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>📅</span>
            <p style={userInfoStyle}>
            Tempo di partenza: <strong>{currentDate.toString()}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>📆</span>
            <p style={userInfoStyle}>
            Orario di fine: <strong>{futureDate.toString()}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>🚗</span>
            <p style={userInfoStyle}>
            Targa: <strong>{licensePlate}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>✉️</span>
            <p style={userInfoStyle}>
              E-mail: <strong>{email}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>💳</span>
            <p style={userInfoStyle}>
            Metodo di pagamento selezionato: <strong>{selectedPaymentMethod}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>💶</span>
            <p style={totalPriceStyle}>
            Prezzo totale: <strong>€{total}</strong>
            </p>
          </div>
          <div style={userInfoStyleContainer}>
            <span style={iconStyle}>💶</span>
            <p style={totalPriceStyle}>
            Pagamento riuscito: <strong>SÌ</strong>
            </p>
          </div>
          <div
            style={{
              ...userInfoStyleContainer,
              backgroundColor: "",
              flexDirection: "column",
            }}
          >
            <b>**IMPORTANTE**</b>
            <p style={{ margin: 0 }}>
              ESPORRE SUL CRUSCOTTO UN AVVISO BEN VISIBILE DI AVVENUTO PAGAMENTO
              DELLA SOSTA CON L' APP MOVEA SIMPLE PAY.
            </p>
          </div>
          <button
            style={{
              ...userInfoStyleContainer,
              backgroundColor: "greenyellow",
              width: "40%",
              cursor: "pointer",
              marginTop: 20,
              justifyContent:'center'
            }}
            onClick={() => {
                setPaypalButton(false)
              setSelectedCity("");
              setSelectedParking("");
              setCurrentDateTime(new Date());
              setSelectedDuration("ORARIO");
              setSelectedPaymentMethod("");
              setNumUnits(1);
              setTermsChecked(false);
              setPrivacyChecked(false);
              setEmail("");
              setLicensePlate("");
            }}
          >
            COMPRA UN ALTRO BIGLIETTO
          </button>
        </div>
      ) : (
        <div>
          <div style={{...userInfoStyleContainer, marginBottom:20, backgroundColor:"", border:'0px'}}>
            
            <p style={{...totalPriceStyle, fontSize:22}}>
            Prezzo totale: <strong>€{total}</strong>
            </p>
          </div>
          {paymentError && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {paymentError}
            </div>
          )}
          <PayPalScriptProvider
            options={{
              "client-id":
                "AcXXgI7Rky5SSHxv45qOblO1fzrYtXtU4kM4WC4rnDqS4oaFQEB3WqbcefdhhQIVE2w_3R_bDzFu13F1",
              currency: "EUR",
              disableFunding:'card',
              enableFunding:'paylater'
            }}
          >
            <PayPalButtons
            style={{
                layout: "vertical",
                fundingicons: true, // Display funding icons
              }}
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: total,
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(function(details) {
                  // Payment is successful, execute your logic here
                  console.log("Payment done");
                  setPaymentSuccessful(true);
                  onSuccess();
                });
              }}
              onError={(err) => {
                // Handle payment errors here
                setPaymentError("Pagamento fallito. Per favore riprova più tardi.");
              }}
              
            />
          </PayPalScriptProvider>
          
          <div style={{width:'100%', display:'flex', justifyContent:'center'}}> <button
            type="button"
            style={buttonStyle}
            onClick={()=>{
              setPaypalButton(false)
            }}
          >
            Indietro
          </button></div>
        </div>
      )}
    </div>
  );
}

export default PayPalButton;
