import React, { useState, useEffect } from "react";
import { ref, get, database } from "../config/firebase";
import { FaCoins } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { query, orderByKey, limitToLast } from "firebase/database";

function AdminStats() {
  const [error, setError] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [parkings, setParkings] = useState([]);
  const [selectedParking, setSelectedParking] = useState(null);
  const [showCustomerData, setShowCustomerData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [cityEarnings, setCityEarnings] = useState({});
  const [parkingEarnings, setParkingEarnings] = useState({});

  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    minWidth: isSmallScreen ? "100%" : 920,
    height: "100vh",
    overflowY: "auto", // Add this
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    backgroundColor: "#f7f7f7",
    margin: "0 auto",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    margin: isSmallScreen ? 0 : 20,
  };

  const headingStyle = {
    fontSize: "28px",
    marginBottom: "0px",
    textAlign: "center",
    padding: 15,
    color: "#81c57a",
  };

  const costdiv = {
    backgroundColor: "white",
    padding: 20,
    width: isSmallScreen ? "50%" : "30%",
    height: isSmallScreen ? 120 : 100,
    borderRadius: 10,
    marginBottom: 20,
  };

  const citiesdiv = {
    backgroundColor: "white",
    padding: 20,
    width: isSmallScreen ? "90%" : "70%",
    borderRadius: 10,
    marginLeft: 20,
    overflowY: "auto",
    maxHeight: "250px",
  };

  const parkingsdiv = {
    backgroundColor: "white",
    padding: 20,
    width: isSmallScreen ? "90%" : "70%",
    borderRadius: 10,
    overflowY: "auto",
    minHeight: "200px",
    margin: "auto",
    marginTop: 20,
    marginBottom: 70,
  };

  const tableStyle = {
    width: "90%",
    height: "100vh",
    padding: "20px",
    margin: "0 auto",
    backgroundColor: "white",
    borderRadius: 10,
    overflowY: "auto",
  };
  const thStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#81c57a",
    fontWeight: "bold",
  };

  const tdStyle = {
    border: "0.5px solid #ddd",
    padding: "8px",
  };

  const closeIconStyle = {
    cursor: "pointer",
    fontSize: 20,
    position: "absolute",
    top: 10,
    right: 10,
    color: "red",
  };

  const firstdiv = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center vertically
    alignItems: "center", // Center horizontally
    flex: 1,
    padding: 20,
    backgroundColor: "",
    marginTop: 0,
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = ref(database, "customers");
      const customersQuery = query(customersRef, orderByKey());

      try {
        const snapshot = await get(customersQuery);
        if (snapshot.exists()) {
          const customersData = snapshot.val();
          const customersArray = Object.values(customersData).reverse();
          setCustomerData(customersArray);

          // Calculate total earnings
          const total = customersArray.reduce((acc, customer) => {
            if (customer && customer.price) {
              return acc + parseFloat(customer.price);
            }
            return acc;
          }, 0);
          setTotalCost(total);

          // Calculate city-wise earnings
          const earningsByCity = {};
          customersArray.forEach((customer) => {
            if (customer.selectedCity && customer.price) {
              const city = customer.selectedCity;
              if (!earningsByCity[city]) {
                earningsByCity[city] = 0;
              }
              earningsByCity[city] += parseFloat(customer.price);
            }
          });
          setCityEarnings(earningsByCity);

          // Calculate parking-wise earnings
          if (selectedCity) {
            const earningsByParking = {};
            customersArray.forEach((customer) => {
              if (
                customer.selectedCity === selectedCity &&
                customer.selectedParking &&
                customer.price
              ) {
                const parking = customer.selectedParking;
                if (!earningsByParking[parking]) {
                  earningsByParking[parking] = 0;
                }
                earningsByParking[parking] += parseFloat(customer.price);
              }
            });
            setParkingEarnings(earningsByParking);
          }
        }
      } catch (error) {
        setError("Error fetching customers: " + error.message);
        console.error("Error fetching customers: ", error);
      }
    };

    const fetchCities = async () => {
      const citiesRef = ref(database, "cities");
      try {
        const snapshot = await get(citiesRef);
        if (snapshot.exists()) {
          const citiesData = snapshot.val();
          const cityNames = Object.keys(citiesData);
          setCities(cityNames);
        }
      } catch (error) {
        setError("Error fetching cities: " + error.message);
        console.error("Error fetching cities: ", error);
      }
    };

    fetchCustomers();
    fetchCities();
  }, [selectedCity]);

  // Fetch parkings when selectedCity changes
  useEffect(() => {
    if (selectedCity) {
      const parkingsRef = ref(database, `cities/${selectedCity}`);
      get(parkingsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const parkingData = snapshot.val();
            const parkingNames = Object.keys(parkingData);
            setParkings(parkingNames);
          }
        })
        .catch((error) => {
          setError("Error fetching parkings: " + error.message);
          console.error("Error fetching parkings: ", error);
        });
    }
  }, [selectedCity]);

  const filteredCustomerData = customerData.filter(
    (customer) =>
      customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.licensePlate.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.startingTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.endingTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.selectedDuration
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      customer.numUnits.toString().includes(searchQuery) ||
      customer.selectedPaymentMethod
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      customer.price.toString().includes(searchQuery)
  );

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>STATISTICHE</h2>
      {showCustomerData ? (
        <div style={tableStyle}>
          <input
            type="text"
            placeholder="Cerca i record"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              margin: "10px 0",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "10px",
              width: "25%",
              fontSize: "16px",
            }}
          />
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "20px",
              cursor: "pointer",
              fontSize: 15,
              fontWeight: "bold",
              alignSelf: "flex-end",
              marginLeft: 15,
            }}
            onClick={() => {
              setShowCustomerData(false);
              setSelectedParking("");
            }}
          >
            CHIUDI TAVOLO
          </button>

          {/* Add search input field */}

          <table>
            <thead>
              <tr>
                <th style={thStyle}>E-mail</th>
                <th style={thStyle}>Targa</th>
                <th style={thStyle}>Tempo di partenza</th>
                <th style={thStyle}>Orario di fine</th>
                <th style={thStyle}>Durata selezionata</th>
                <th style={thStyle}>Credit hours</th>
                <th style={thStyle}>Numero di unità</th>
                <th style={thStyle}>Metodo di pagamento selezionato</th>
                <th style={thStyle}>Prezzo</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomerData.length > 0 ? (
                filteredCustomerData.map((customer, index) => (
                  <tr key={index}>
                    <td style={tdStyle}>{customer.email}</td>
                    <td style={tdStyle}>{customer.licensePlate}</td>
                    <td style={tdStyle}>{customer.startingTime}</td>
                    <td style={tdStyle}>{customer.endingTime}</td>
                    <td style={tdStyle}>{customer.selectedDuration}</td>
                    <td style={tdStyle}>{customer.creditHours}</td>
                    <td style={tdStyle}>{customer.numUnits}</td>
                    <td style={tdStyle}>{customer.selectedPaymentMethod}</td>
                    <td style={tdStyle}>{customer.price}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={tdStyle}>
                    Nessun record trovato.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <div style={firstdiv}>
            <div style={costdiv}>
              <div>
                <FaCoins style={{ fontSize: 40, color: "#81c57a" }} />
              </div>
              <div style={{ fontSize: 20, marginTop: 10, fontWeight: 600 }}>
                Guadagni complessivi: €{totalCost.toFixed(2)}
              </div>
            </div>

            <div style={citiesdiv}>
              <h3 style={{ marginBottom: 5 }}>Città disponibili</h3>
              <p style={{ margin: 0, padding: 0 }}>
                Fare clic su una città per visualizzare i dettagli del
                parcheggio
              </p>
              {cities.map((cityName, index) => (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    width: isSmallScreen ? "80%" : "50%",
                    margin: "auto",
                    marginTop: 10,
                    borderRadius: 10,
                    backgroundColor:
                      selectedCity === cityName ? "#81c57a" : "lightgrey",
                  }}
                  onClick={() => setSelectedCity(cityName)}
                >
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {cityName} - Guadagni: €
                    {cityEarnings[cityName]
                      ? cityEarnings[cityName].toFixed(2)
                      : "0.00"}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div style={parkingsdiv}>
            {selectedCity ? (
              <div>
                <h3 style={{ marginTop: 5, marginBottom: 5 }}>
                  Parcheggi dentro {selectedCity}
                </h3>
                <p style={{ margin: 0, padding: 0 }}>
                  Fare clic su un parcheggio per visualizzarne i dati del
                  cliente
                </p>
                {parkings.length > 0 ? (
                  parkings.map((parkingName, index) => (
                    <div
                      key={index}
                      style={{
                        margin: 10,
                        padding: 10,
                        textAlign: "center",
                        cursor: "pointer",
                        width: isSmallScreen ? "80%" : "50%",
                        margin: "auto",
                        marginTop: 10,
                        borderRadius: 10,
                        backgroundColor:
                          selectedParking === parkingName
                            ? "#81c57a"
                            : "lightgrey",
                      }}
                      onClick={() => {
                        setSelectedParking(parkingName);
                        setShowCustomerData(true);
                      }}
                    >
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {parkingName} - Guadagni: €
                        {parkingEarnings[parkingName]
                          ? parkingEarnings[parkingName].toFixed(2)
                          : "0.00"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>Divieto di sosta nella città selezionata.</p>
                )}
              </div>
            ) : (
              <p>
                Seleziona una città per visualizzare i dettagli del parcheggio.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminStats;
