import React from "react";
import { FaCar, FaEnvelope, FaCalendar } from "react-icons/fa";

const CustomInput = ({ icon, value, onChange, placeholder }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      position: "relative",
      marginTop:8,
      borderRadius: "10px"
    }}
  >
    <svg
      style={{
        position: "absolute",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
      }}
      width="20"
      height="20"
    >
      {icon}
    </svg>
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        paddingLeft: "40px",
        color: "",
        paddingBottom: "10px",
        paddingTop: "5px",
        width: "100%", // Adjusted width to 100%
        backgroundColor: "white",
        borderRadius: "0px",
        border: "0px solid green",
        height: "40px",
        fontWeight: "italic",
        fontSize: 15,
        borderRadius: "10px",
        color:'#818180',
        
      }}
    />
  </div>
);

export default CustomInput;
