import React, { useState } from 'react';

const HoverPopupDiv = ({ popupContent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        /*onClick={openModal}*/
        style={{
          backgroundColor: 'darkred', // Initial background color (dark red)
          color: 'white',
          cursor: 'pointer',
          padding: '10px',
          fontWeight: 'bold',
          textAlign: 'center',
          transition: 'background-color 0.3s', // Add transition for smooth hover effect
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = 'red'; // Change background color on hover
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = 'darkred'; // Restore initial dark red color on mouse leave
        }}
      >
        COMPLETARE CORETTAMENTE I SEGUENTI CAMPI!  
      </div>
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '999',
          }}
        >
          <div
            style={{
              position: 'relative',
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '5px',
              width: '400px', // Adjust the width
              height: '400px', // Adjust the height
            }}
          >
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '16px',
              }}
            >
              X
            </button>
            {popupContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default HoverPopupDiv;
