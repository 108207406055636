import React, { useState, useEffect } from "react";
import "./App.css";
import Signup from "./screens/singup";
import Signin from "./screens/signin";
import CustomerHome from "./screens/customerhome";
import Adminhome from "./screens/adminhome";
import ValetHome from "./screens/valethome";
import AddCity from "./screens/AddCity";
import AddParking from "./screens/AddParking";
import EditCity from "./screens/EditCity";
import AdminStats from "./screens/AdminStats";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  redirect,
} from "react-router-dom";
import EditParking from "./screens/EditParking";

function App() {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [isValetLoggedIn, setIsValetLoggedIn] = useState(false);

  useEffect(() => {
    // Check for isLoggedIn status in localStorage
    const storedAdminLoggedIn = localStorage.getItem("isAdminLoggedIn");
    const storedValetLoggedIn = localStorage.getItem("isValetLoggedIn");
    console.log(storedAdminLoggedIn);
    console.log(storedValetLoggedIn);
    if (storedAdminLoggedIn === "true") {
      setIsAdminLoggedIn(true);
    }
    if (storedValetLoggedIn === "true") {
      setIsValetLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/admin-movea"
            element={
              <Signin
                isAdminLoggedIn={isAdminLoggedIn}
                setIsAdminLoggedIn={setIsAdminLoggedIn}
                isValetLoggedIn={isValetLoggedIn}
                setIsValetLoggedIn={setIsValetLoggedIn}
              />
            }
          />
          <Route path="/" element={<CustomerHome />} />
          <Route
            path="/adminhome"
            element={
              isAdminLoggedIn ? (
                <Adminhome />
              ) : (
                <Signin
                  isAdminLoggedIn={isAdminLoggedIn}
                  setIsAdminLoggedIn={setIsAdminLoggedIn}
                  isValetLoggedIn={isValetLoggedIn}
                  setIsValetLoggedIn={setIsValetLoggedIn}
                />
              )
            }
          >
            {/* Nested routes */}
            <Route path="" element={<AdminStats />} />
            <Route path="addcity" element={<AddCity />} />
            <Route path="addparking" element={<AddParking />} />
            <Route path="editcity" element={<EditCity />} />
            <Route path="editparking" element={<EditParking />} />
            <Route path="addvalet" element={<Signup />} />
          </Route>
            <Route path="/parking-operator" element={isValetLoggedIn ? (
                <ValetHome />
              ) : (
                <Signin
                  isAdminLoggedIn={isAdminLoggedIn}
                  setIsAdminLoggedIn={setIsAdminLoggedIn}
                  isValetLoggedIn={isValetLoggedIn}
                  setIsValetLoggedIn={setIsValetLoggedIn}
                />
              )} />
        
        </Routes>
      </div>
    </Router>
  );
}

export default App;
