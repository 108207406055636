import React, { useState, useEffect } from "react";
import { ref, get, database } from "../config/firebase";
import {
  Outlet,
  Link,
  useLocation,
  useNavigate,
  useFetcher,
} from "react-router-dom";
import { query, orderByKey, limitToLast } from "firebase/database";

   function ValetHome() {
    const navigate = useNavigate();
     const [records, setRecords] = useState([]);
     const [search, setSearch] = useState("");
     const [selectedCity, setSelectedCity] = useState("");
     const [cities, setCities] = useState([]);
     const [alottedParking, setAlottedParking] = useState("");
   
     useEffect(() => {
       setAlottedParking(localStorage.getItem("alottedParking"));
     });
   
     useEffect(() => {
       const citiesRef = ref(database, "cities");
       get(citiesRef)
         .then((snapshot) => {
           if (snapshot.exists()) {
             const citiesData = snapshot.val();
             const cityNames = Object.keys(citiesData);
             setCities(cityNames);
           }
         })
         .catch((error) => {
           console.error("Error fetching cities: ", error);
         });
     }, []);
   
     useEffect(() => {
      const customRef = ref(database, "customers");
      const customQuery = query(customRef, orderByKey()); 
    
      get(customQuery)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const customers = Object.values(snapshot.val()).reverse();
            setRecords(customers);
          }
        })
        .catch((error) => {
          console.error("Error fetching customers: ", error);
        });
    }, [selectedCity]);
    
   
     function revertItalianTimeFormat(dateUTC) {
      if (dateUTC) {
        const dateParts = dateUTC.split(', ');
        if (dateParts.length === 2) {
          const [dayOfWeek, dateString] = dateParts;
          if (dateParts) {
            return dateParts[1];
          }
        }
      }
      return null; 
    }
    
    function getCurrentDateTime() {
      const now = new Date();
      const daysOfWeek = [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ];
      const day = now.getDate().toString().padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const year = now.getFullYear();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const dayOfWeek = daysOfWeek[now.getDay()];
    
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
    
    
    function filterRecordsWithFutureEndingTime(records) {
      const today = getCurrentDateTime();
      return records.filter((record) => {
        if (record.endingTime) {
          const jsEndingTime = revertItalianTimeFormat(record.endingTime);
          return jsEndingTime >= today;
        }
        return false; 
      });
    }


const filteredRecords = 
  records.filter((record) => {
    if (!record) {
      return false;
    }
    const searchTerm = search.toLowerCase();
    const parkingFilter = alottedParking
      ? record.selectedParking === alottedParking
      : true;

    return (
      parkingFilter &&
      ((record.licensePlate &&
        record.licensePlate.toLowerCase().includes(searchTerm)) ||
        (record.email && record.email.toLowerCase().includes(searchTerm)) ||
        (record.selectedDuration &&
          record.selectedDuration.toLowerCase().includes(searchTerm)) ||
        (record.selectedNumUnits &&
          record.selectedNumUnits.toString().includes(searchTerm)) ||
        (record.currentDateTime &&
          record.currentDateTime.toLowerCase().includes(searchTerm)) ||
        (record.selectedPaymentMethod &&
          record.selectedPaymentMethod.toLowerCase().includes(searchTerm)) ||
        (record.selectedParking &&
          record.selectedParking.toLowerCase().includes(searchTerm))
      )
    );
  })
;

     
   
     return (
       <div style={containerStyle}>
         <div style={headerStyle} className="header">
           <h4 style={{ color: "green", marginLeft: 10 }}>
             {" "}
             DASHBOARD PERCHEGGIATORE
           </h4>
           <button
             style={{
               width: 150,
               marginRight: 20,
               height: 40,
               marginTop: 20,
               backgroundColor: "black",
               color: "white",
               borderRadius: 20,
               fontSize: 18,
               cursor: "pointer",
             }}
             onClick={() => {
               localStorage.removeItem("isValetLoggedIn");
               navigate("/admin-movea");
             }}
           >
             Disconnettersi
           </button>
         </div>
         <div style={searchContainerStyle}>
           <input
             type="text"
             placeholder="Cerca i record"
             value={search}
             onChange={(e) => setSearch(e.target.value)}
             style={inputStyle}
           />
         </div>
         <table style={tableStyle}>
           <thead>
             <tr>
               <th style={thStyle}>Targa</th>
               <th style={thStyle}>Durata selezionata</th>
               <th style={thStyle}>Unità di durata</th>
               <th style={thStyle}>Credit Hours</th>
               <th style={thStyle}>Tempo di partenza</th>
               <th style={thStyle}>Orario di fine</th>
               <th style={thStyle}>Prezzo</th>
             </tr>
           </thead>
           <tbody>
             {filteredRecords.length === 0 ? (
               <tr>
                 <td colSpan="10">No records found.</td>
               </tr>
             ) : (
               filteredRecords.map((record, index) => (
                 <tr key={index}>
                   <td style={tdStyle}>{record.licensePlate}</td>
                   <td style={tdStyle}>{record.selectedDuration}</td>
                   <td style={tdStyle}>{record.numUnits}</td>
                   <td style={tdStyle}>{record.creditHours}</td>
                   <td style={tdStyle}>{record.startingTime}</td>
                   <td style={tdStyle}>{record.endingTime}</td>
                   <td style={tdStyle}>{record.price}</td>
                 </tr>
               ))
             )}
           </tbody>
         </table>
       </div>
     );
   }
   
   const containerStyle = {
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     minHeight: "100vh", // Changed to minHeight
     textAlign: "center",
   };
   
   const headerStyle = {
     backgroundColor: "white",
     color: "#fff",
     height: 70,
     fontSize: "20px",
     padding: "10px",
     paddingRight: 0,
     width: "100%",
     border: "1px solid",
     boxShadow: "0 0 50px rgba(0, 0, 0, 0.1)",
     justifyContent: "space-between",
     display: "flex",
     flexWrap: "nowrap",
   };
   
   const searchContainerStyle = {
   };
   
   const inputStyle = {
     marginLeft: 20,
     marginBottom: "20px",
     padding: "10px",
     width: 250,
     borderRadius: 20,
     marginTop: 20,
     fontSize:15
   };
   
   const tableStyle = {
     borderCollapse: "collapse",
     width: "90%", // Adjusted to 100%
     margin: "0 auto",
     textAlign: "center",
   };
   
   const thStyle = {
     border: "1px solid #ddd",
     padding: "8px",
     backgroundColor: "#81c57a",
     fontWeight: "bold",
   };
   
   const tdStyle = {
     border: "1px solid #ddd",
     padding: "8px",
   };
   
   export default ValetHome;
   