import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, useDeviceLanguage , signInWithEmailAndPassword} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase, ref, set, get , onValue,push, remove, update} from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBxYDsYmjm7FWzClaQiDeXLHqTInuwGeO4",
    authDomain: "parkingsystem-eed6b.firebaseapp.com",
    projectId: "parkingsystem-eed6b",
    storageBucket: "parkingsystem-eed6b.appspot.com",
    messagingSenderId: "116550379009",
    databaseURL: "https://parkingsystem-eed6b-default-rtdb.firebaseio.com/",
    appId: "1:116550379009:web:cfdff52221211b15468200"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
const database = getDatabase(app);
  export { auth, firestore , createUserWithEmailAndPassword, database, ref, set, signInWithEmailAndPassword,get,onValue,push, remove, update};
