import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaEdit, FaUserPlus, FaUser } from "react-icons/fa";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
};

const headerStyle = {
  backgroundColor: "white",
  color: "#fff",
  height: 70,
  fontSize: "20px",
  padding: "10px",
  paddingRight: 0,
  boxShadow: "0 0 50px rgba(0, 0, 0, 0.1)",
  justifyContent: "space-between",
  display: "flex",
  flexWrap: "nowrap",
  minWidth: 1393,
};
const smallScreenHeader = {
  backgroundColor: "white",
  color: "#fff",
  height: 70,
  fontSize: "20px",
  padding: "10px",
  paddingRight: 0,
  boxShadow: "0 0 50px rgba(0, 0, 0, 0.1)",
  justifyContent: "space-between",
  display: "flex",
  flexWrap: "nowrap",
  width: "100%",
};

const mainContentStyle = {
  flex: 1,
  margin: 0,
  padding: 0,
  overflowY: "auto",
  backgroundColor: "lightgrey",
};
const smallMainContentStyle = {
  flex: 1,
  margin: 0,
  padding: 0,
  overflowY: "auto",
  backgroundColor: "lightgrey",
  height: "100vh",
};

const contentContainerStyle = {
  display: "flex",
  flex: 1,
  minWidth: 1403,
};

const sidebarStyle = {
  minWidth: 300,
  backgroundColor: "white",
  color: "#fff",
  padding: "0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderTop: "3px solid #dde4fe",
};

const ulStyle = {
  listStyle: "none",
  padding: 0,
  width: "100%",
};
const smallUlStyle = {
  listStyle: "none",
  padding: 0,
  width: "50%",
};

const liStyle = {
  marginBottom: "10px",
  width: "100%",
  textAlign: "center",
};
const smallLiStyle = {
  marginBottom: "10px",
  width: "100%",
  textAlign: "center",
};

const linkStyle = {
  textDecoration: "none",
  color: "blue",
  fontSize: "18px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  borderRadius: "5px",
  transition: "background-color 0.3s",
};
const smallLinkStyle = {
  textDecoration: "none",
  color: "blue",
  fontSize: "18px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  borderRadius: "5px",
  transition: "background-color 0.3s",
};

const selectedLinkStyle = {
  ...liStyle,
  backgroundColor: "#007bff",
  transition: "background-color 0.3s",
};

const iconStyle = {
  marginRight: "10px",
};

const dropdownButtonStyle = {
  display: "block",
  textDecoration: "none",
  color: "blue",
  fontSize: "18px",
  padding: "10px",
  borderRadius: "5px",
  transition: "background-color 0.3s",
  textAlign: "center",
  width: "50%",
  margin: "auto",
  marginTop: 20,
  marginBottom: 20,
};

function Adminhome() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("Stats");
  const location = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.visualViewport.width  <= 800); // You can adjust the breakpoint (800) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdownMenuClick = () => {
    // Toggle the dropdown menu's visibility
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleOptionClick = () => {
    setIsDropdownOpen(false); // Close the dropdown menu when an option is clicked
  };

  const menuItems = [
    {
      name: "Stats",
      icon: <FaUser />,
      to: "/adminhome/",
      label: "Stats",
    },
    {
      name: "Add City",
      icon: <FaPlus />,
      to: "/adminhome/addcity",
      label: "Aggiungi Città",
    },
    {
      name: "Add Parking",
      icon: <FaPlus />,
      to: "/adminhome/addparking",
      label: "Aggiungi Parcheggio",
    },
    {
      name: "Edit City",
      icon: <FaEdit />,
      to: "/adminhome/editcity",
      label: "Modifica Città",
    },
    {
      name: "Edit Parking",
      icon: <FaEdit />,
      to: "/adminhome/editparking",
      label: "Modifica Parcheggio",
    },
    {
      name: "Add Valet",
      icon: <FaUserPlus />,
      to: "/adminhome/addvalet",
      label: "Aggiungere Parcheggiatore",
    },
  ];

  return (
    <div style={containerStyle} className="admin-container">
      {isSmallScreen ? ( 
        <div style={{}}>
          <div style={smallScreenHeader} className="header">
            <h4 style={{ color: "green", marginLeft: 20 }}>
              DASHBOARD AMMINISTRATIVO
            </h4>
            <button
              style={{
                width: 150,
                marginRight: 20,
                height: 40,
                marginTop: 20,
                backgroundColor: "black",
                color: "white",
                borderRadius: 20,
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => {
                localStorage.removeItem("isAdminLoggedIn");
                navigate("/admin-movea");
              }}
            >
              Disconnettersi
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={dropdownButtonStyle}
              onClick={handleDropdownMenuClick}
            >
              Menù
            </button>
            {isDropdownOpen && (
              <ul style={smallUlStyle}>
                {menuItems.map((item) => (
                  <li
                    key={item.name}
                    style={
                      selectedItem === item.name
                        ? { ...selectedLinkStyle, color: "#fff" }
                        : smallLiStyle
                    }
                  >
                    <Link
                      to={item.to}
                      style={smallLinkStyle}
                      onClick={() => {
                        handleOptionClick();
                        setSelectedItem(item.name);
                      }}
                    >
                      {item.icon}
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 16,
                          fontWeight: "bold",
                          color:
                            selectedItem === item.name ? "#fff" : "inherit",
                        }}
                      >
                        {item.label}
                      </p>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div style={smallMainContentStyle} className="main-content">
            <Outlet />
          </div>
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100vh" }}
        >
          <div style={headerStyle} className="header">
            <h4 style={{ color: "green", marginLeft: 20 }}>
              DASHBOARD AMMINISTRATIVO
            </h4>
            <button
              style={{
                width: 150,
                marginRight: 20,
                height: 40,
                marginTop: 20,
                backgroundColor: "black",
                color: "white",
                borderRadius: 20,
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => {
                localStorage.removeItem("isAdminLoggedIn");
                navigate("/");
              }}
            >
              Disconnettersi
            </button>
          </div>
          <div style={contentContainerStyle}>
            <div style={sidebarStyle} className="sidebar">
              // Display the regular menu for larger screens
              <ul style={ulStyle}>
                {menuItems.map((item) => (
                  <li
                    key={item.name}
                    style={
                      selectedItem === item.name
                        ? { ...selectedLinkStyle, color: "#fff" }
                        : liStyle
                    }
                  >
                    <Link to={item.to} style={linkStyle} 
                     onClick={() => {
                      
                      setSelectedItem(item.name);
                    }}>
                      {item.icon}
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 16,
                          fontWeight: "bold",
                          color:
                            selectedItem === item.name ? "#fff" : "inherit",
                        }}
                      >
                        {item.label}
                      </p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div style={mainContentStyle} className="main-content">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Adminhome;
