import React, { useState, useEffect } from "react";
import { ref, get, database, update, set, remove } from "../config/firebase";
import { parse, format } from "date-fns";
import DatePicker from "react-datepicker"; // Import the date picker library
import "react-datepicker/dist/react-datepicker.css";
const EditParking = () => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [parkingList, setParkingList] = useState([]);
  const [selectedParking, setSelectedParking] = useState(null);
  const [parkingName, setParkingName] = useState("");
  const [hourlyCost1, setHourlyCost1] = useState("");
  const [dailyCost1, setDailyCost1] = useState("");
  const [hourlyCost2, setHourlyCost2] = useState("");
  const [dailyCost2, setDailyCost2] = useState("");
  const [period1StartingTime, setPeriod1StartingTime] = useState(null);
  const [period1EndingTime, setPeriod1EndingTime] = useState(null);
  const [period2StartingTime, setPeriod2StartingTime] = useState(null);
  const [period2EndingTime, setPeriod2EndingTime] = useState(null);
  const [numberOfSlots, setNumberOfSlots] = useState(1);
  const [slot1StartingTime, setSlot1StartingTime] = useState(null);
  const [slot1EndingTime, setSlot1EndingTime] = useState(null);
  const [slot2StartingTime, setSlot2StartingTime] = useState(null);
  const [slot2EndingTime, setSlot2EndingTime] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800); // You can adjust the breakpoint (800) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Function to fetch parking details for the selected city
  const fetchParkingDetails = () => {
    if (selectedCity) {
      const parkingRef = ref(database, `cities/${selectedCity}`);
      get(parkingRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const parkingData = snapshot.val();
            // Convert parkingData object into an array of objects
            const parkingArray = Object.keys(parkingData).map(
              (parkingName) => ({
                name: parkingName,
                ...parkingData[parkingName],
              })
            );
            setParkingList(parkingArray);
          } else {
            // If no parking data exists, set parkingList to an empty array
            setParkingList([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching parking details: ", error);
        });
    }
  };

  useEffect(() => {
    // Fetch the list of cities from Firebase
    const citiesRef = ref(database, "cities");
    get(citiesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const citiesData = snapshot.val();
          const cityNames = Object.keys(citiesData);
          setCities(cityNames);
        }
      })
      .catch((error) => {
        console.error("Error fetching cities: ", error);
      });
  }, []);

  useEffect(() => {
    // Fetch parking details when the selected city changes
    fetchParkingDetails();
  }, [selectedCity]);

  const handleParkingEdit = (
    name,
    period1Start,
    period1End,
    hourly1,
    daily1,
    period2Start,
    period2End,
    hourly2,
    daily2,
    slotunits,
    slot1S,
    slot1E,
    slot2S,
    slot2E
  ) => {
    setSelectedParking(name);
    setParkingName(name);
    setHourlyCost1(hourly1);
    setDailyCost1(daily1);
    setHourlyCost2(hourly2);
    setDailyCost2(daily2);
    const p1startingDate = parse(period1Start, "dd/MM/yyyy", new Date());
    const p1endingDate = parse(period1End, "dd/MM/yyyy", new Date());
    const p2startingDate = parse(period2Start, "dd/MM/yyyy", new Date());
    const p2endingDate = parse(period2End, "dd/MM/yyyy", new Date());
    setPeriod1StartingTime(p1startingDate);
    setPeriod1EndingTime(p1endingDate);
    setPeriod2StartingTime(p2startingDate);
    setPeriod2EndingTime(p2endingDate);
    if (slotunits === 1) {
      setNumberOfSlots(1);
      setSlot1StartingTime(parse(slot1S, "HH:mm", new Date()));
      setSlot1EndingTime(parse(slot1E, "HH:mm", new Date()));
    }
    if (slotunits === 2) {
      setNumberOfSlots(2);
      setSlot1StartingTime(parse(slot1S, "HH:mm", new Date()));
      setSlot1EndingTime(parse(slot1E, "HH:mm", new Date()));
      setSlot2StartingTime(parse(slot2S, "HH:mm", new Date()));
      setSlot2EndingTime(parse(slot2E, "HH:mm", new Date()));
    }
  };

  const handleParkingUpdate = () => {
    if (selectedCity && selectedParking) {
      const parkingRef = ref(
        database,
        `cities/${selectedCity}/${selectedParking}`
      );
      const formattedP1StartingTime = format(period1StartingTime, "dd/MM/yyyy");
      const formattedP1EndingTime = format(period1EndingTime, "dd/MM/yyyy");
      const formattedP2StartingTime = format(period2StartingTime, "dd/MM/yyyy");
      const formattedP2EndingTime = format(period2EndingTime, "dd/MM/yyyy");
      // Check if the parking name has changed
      if (selectedParking !== parkingName) {
        // Create a new parking entry with the updated values
        const newParkingRef = ref(
          database,
          `cities/${selectedCity}/${parkingName}`
        );
        const updatedParking = {
          period1hourly: hourlyCost1,
          period1daily: dailyCost1,
          period2hourly: hourlyCost2,
          period2daily: dailyCost2,
          period1StartingTime: formattedP1StartingTime, // Store timestamps
          period1EndingTime: formattedP1EndingTime, // Store timestamps
          period2StartingTime: formattedP2StartingTime, // Store timestamps
          period2EndingTime: formattedP2EndingTime,
        };
        if (numberOfSlots === 1) {
          updatedParking.slotUnits = 1;
          updatedParking.slot1StartingTime = format(slot1StartingTime, "HH:mm");
          updatedParking.slot1EndingTime = format(slot1EndingTime, "HH:mm");
        } else if (numberOfSlots === 2) {
          updatedParking.slotUnits = 2;
          updatedParking.slot1StartingTime = format(slot1StartingTime, "HH:mm");
          updatedParking.slot1EndingTime = format(slot1EndingTime, "HH:mm");
          updatedParking.slot2StartingTime = format(slot2StartingTime, "HH:mm");
          updatedParking.slot2EndingTime = format(slot2EndingTime, "HH:mm");
        }
        set(newParkingRef, updatedParking)
          .then(() => {
            // Remove the older parking entry
            remove(parkingRef)
              .then(() => {
                setSelectedParking(null);
                setHourlyCost1(0);
                setDailyCost1(0);
                setPeriod1StartingTime(null);
                setPeriod1EndingTime(null);
                setHourlyCost2(0);
                setDailyCost2(0);
                setPeriod2StartingTime(null);
                setPeriod2EndingTime(null);
                fetchParkingDetails();
              })
              .catch((error) => {
                console.error("Error removing older parking entry: ", error);
              });
          })
          .catch((error) => {
            console.error("Error updating parking details: ", error);
          });
      } else {
        // If the parking name hasn't changed, update the existing entry
        const updatedParking = {
          period1hourly: hourlyCost1,
          period1daily: dailyCost1,
          period2hourly: hourlyCost2,
          period2daily: dailyCost2,
          period1StartingTime: formattedP1StartingTime, // Store timestamps
          period1EndingTime: formattedP1EndingTime, // Store timestamps
          period2StartingTime: formattedP2StartingTime, // Store timestamps
          period2EndingTime: formattedP2EndingTime,
        };
        if (numberOfSlots === 1) {
          updatedParking.slotUnits = 1;
          updatedParking.slot1StartingTime = format(slot1StartingTime, "HH:mm");
          updatedParking.slot1EndingTime = format(slot1EndingTime, "HH:mm");
        } else if (numberOfSlots === 2) {
          updatedParking.slotUnits = 2;
          updatedParking.slot1StartingTime = format(slot1StartingTime, "HH:mm");
          updatedParking.slot1EndingTime = format(slot1EndingTime, "HH:mm");
          updatedParking.slot2StartingTime = format(slot2StartingTime, "HH:mm");
          updatedParking.slot2EndingTime = format(slot2EndingTime, "HH:mm");
        }
        update(parkingRef, updatedParking)
          .then(() => {
            setSelectedParking(null);
            setHourlyCost1(0);
            setDailyCost1(0);
            setPeriod1StartingTime(null);
            setPeriod1EndingTime(null);
            setHourlyCost2(0);
            setDailyCost2(0);
            setPeriod2StartingTime(null);
            setPeriod2EndingTime(null);
            fetchParkingDetails();
          })
          .catch((error) => {
            console.error("Error updating parking details: ", error);
          });
      }
    }
  };

  const handleParkingDelete = (name) => {
    if (selectedCity) {
      // Display a confirmation dialog before deleting
      const confirmDelete = window.confirm(
        `Sei sicuro di voler eliminare la voce del parcheggio? "${name}"?`
      );

      if (confirmDelete) {
        const parkingRef = ref(database, `cities/${selectedCity}/${name}`);
        remove(parkingRef)
          .then(() => {
            // Fetch the latest data after deleting
            fetchParkingDetails();
          })
          .catch((error) => {
            console.error("Error deleting parking entry: ", error);
          });
      }
    }
  };

  const containerStyle = {
    width: isSmallScreen ? "90%" : 600,
    margin: "20px auto",
    padding: "20px", // Add padding
    backgroundColor: "#f7f7f7", // Background color
    borderRadius: "10px", // Rounded corners
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)", // Box shadow
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: isSmallScreen ? 0 : 20
  };

  const headingStyle = {
    fontSize: "24px",
    marginBottom: "20px",
    textAlign: "center",
    color: "#81c57a",
  };

  const labelStyle = {
    fontWeight: "",
    fontSize: "19px", // Added font size
  };

  const selectStyle = {
    width: "70%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px", // Rounded corners for select
    marginBottom: "10px",
    fontSize: 20,
  };

  const ulStyle = {
    listStyleType: "none",
    padding: "0",
  };

  const liStyle = {
    border: "1px solid #ccc",
    padding: "20px",
    margin: "10px 0",
    position: "relative",
  };

  const liDivStyle = {
    width: isSmallScreen ? "100%" : 500,
    display: "flex",
    justifyContent: isSmallScreen ? "center" : "",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  };

  const buttonStyle = {
    padding: "5px 10px",
    marginRight: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    width: 100,
    height: 40,
    fontSize: 15,
    margin: 5,
    borderRadius: 20,
  };

  const deleteButtonStyle = {
    padding: "5px 10px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    height: 40,
    width: 100,
    fontSize: 15,
    borderRadius: 20,
  };

  const editFormStyle = {
    backgroundColor: "#ffff",
    padding: "10px",
    marginTop: "20px",
    border: "1px solid #ccc",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
  };

  const inputStyle = {
    width: "70%",
    padding: "10px",
    marginBottom: "3px",
    fontSize: "15px", // Added font size
    border: "1px solid #ccc",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    WebkitAppearance: "none",
  };
  const periodTimeLabelStyle = {
    fontSize: "16px",
    color: "#333", // You can adjust the color as needed
    marginBottom: "0px",
    marginTop: "0px",
    marginRight: 10,
    margin: 5,
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>MODIFICA PARCHEGGIO</h1>
      <select
        id="cityDropdown"
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
        style={selectStyle}
      >
        <option value="">Seleziona una città</option>
        {cities.map((city) => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>

      {selectedCity && (
        <div>
          <h2 style={headingStyle}>Dettagli parcheggio per {selectedCity}</h2>
          {parkingList.length === 0 ? (
            <p>Nessun parcheggio nella città selezionata.</p>
          ) : (
            <ul style={ulStyle}>
              {parkingList.map((parking) => (
                <li key={parking.name} style={liStyle}>
                  <div style={liDivStyle}>
                    <span style={labelStyle}>
                      Nome del parcheggio: {parking.name}
                    </span>
                    <div>
                      <span style={labelStyle}>
                      Inizio primo periodo 1: {parking.period1StartingTime}
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                      Fine primo periodo 1: {parking.period1EndingTime}
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                        Costo orario: {parking.period1hourly} €
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                        Costo giornaliero: {parking.period1daily} €
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                      Inizio primo periodo 2: {parking.period2StartingTime}
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                      Fine primo periodo 2: {parking.period2EndingTime}
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                        Costo orario: {parking.period2hourly} €
                      </span>
                    </div>
                    <div>
                      <span style={labelStyle}>
                        Costo giornaliero: {parking.period2daily} €
                      </span>
                    </div>
                    {numberOfSlots === 1 && (
                      <div>
                        <div>
                          <span style={labelStyle}>Numero fasce orarie: 1 </span>
                        </div>
                        <div>
                          <span style={labelStyle}>
                          Inizio fascia oraria 1: {parking.slot1StartingTime}
                          </span>
                        </div>
                        <div>
                          <span style={labelStyle}>
                          Fine fascia oraria 1: {parking.slot1EndingTime}
                          </span>
                        </div>
                      </div>
                    )}
                    {numberOfSlots === 2 && (
                      <div>
                        <div>
                          <span style={labelStyle}>Numero fasce orarie: 2 </span>
                        </div>
                        <div>
                          <span style={labelStyle}>
                          Inizio fascia oraria 1: {parking.slot1StartingTime}
                          </span>
                        </div>
                        <div>
                          <span style={labelStyle}>
                          Fine fascia oraria 1: {parking.slot1EndingTime}
                          </span>
                        </div>
                        <div>
                          <span style={labelStyle}>
                          Inizio fascia oraria 2: {parking.slot2StartingTime}
                          </span>
                        </div>
                        <div>
                          <span style={labelStyle}>
                          Fine fascia oraria 2: {parking.slot2EndingTime}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: 20,
                      marginRight: 20,
                    }}
                  >
                    <button
                      onClick={() =>
                        handleParkingEdit(
                          parking.name,
                          parking.period1StartingTime,
                          parking.period1EndingTime,
                          parking.period1hourly,
                          parking.period1daily,
                          parking.period2StartingTime,
                          parking.period2EndingTime,
                          parking.period2hourly,
                          parking.period2daily,
                          parking.slotUnits,
                          parking.slot1StartingTime,
                          parking.slot1EndingTime,
                          parking.slot2StartingTime,
                          parking.slot2EndingTime
                        )
                      }
                      style={buttonStyle}
                    >
                      Modificare
                    </button>
                    <button
                      onClick={() => handleParkingDelete(parking.name)}
                      style={deleteButtonStyle}
                    >
                      Eliminare
                    </button>
                  </div>
                  {selectedParking === parking.name && (
                    <div style={editFormStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 style={{ ...headingStyle, fontSize: "18px" }}>
                          Modifica i dettagli del parcheggio
                        </h2>
                      </div>
                      <label htmlFor="parkingName">Nome del parcheggio:</label>
                      <input
                        type="text"
                        id="parkingName"
                        value={parkingName}
                        onChange={(e) => setParkingName(e.target.value)}
                        style={inputStyle}
                      />

                      
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: 5,
                        }}
                      >
                        <p style={periodTimeLabelStyle}>
                        Aggiornamento Inizio primo periodo 1:{" "}
                        </p>
                        <DatePicker
                          selected={period1StartingTime}
                          onChange={(date) => setPeriod1StartingTime(date)}
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="d/MM/yyyy"
                          placeholderText="Inizio primo periodo 1"
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <p style={periodTimeLabelStyle}>
                        Aggiornamento Fine primo periodo 1 :{" "}
                        </p>
                        <DatePicker
                          selected={period1EndingTime}
                          onChange={(date) => setPeriod1EndingTime(date)}
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="d/MM/yyyy"
                          placeholderText="Fine primo periodo 1"
                        />
                      </div>
                      <label htmlFor="hourlyCost">Costo orario (€):</label>
                      <input
                        type="number"
                        id="hourlyCost"
                        value={hourlyCost1}
                        onChange={(e) =>
                          setHourlyCost1(parseFloat(e.target.value))
                        }
                        style={inputStyle}
                      />
                      <label htmlFor="dailyCost">Costo giornaliero (€):</label>
                      <input
                        type="number"
                        id="dailyCost"
                        value={dailyCost1}
                        onChange={(e) =>
                          setDailyCost1(parseFloat(e.target.value))
                        }
                        style={inputStyle}
                      />
                      
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: 5,
                        }}
                      >
                        <p style={periodTimeLabelStyle}>
                        Aggiornamento Inizio primo periodo 2:{" "}
                        </p>
                        <DatePicker
                          selected={period2StartingTime}
                          onChange={(date) => setPeriod2StartingTime(date)}
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="d/MM/yyyy"
                          placeholderText="Inizio primo periodo 2"
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <p style={periodTimeLabelStyle}>
                        Aggiornamento Fine primo periodo 2 :{" "}
                        </p>
                        <DatePicker
                          selected={period2EndingTime}
                          onChange={(date) => setPeriod2EndingTime(date)}
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="d/MM/yyyy"
                          placeholderText="Fine primo periodo 2"
                        />
                      </div>

                      <label htmlFor="hourlyCost">Costo orario (€):</label>
                      <input
                        type="number"
                        id="hourlyCost"
                        value={hourlyCost2}
                        onChange={(e) =>
                          setHourlyCost2(parseFloat(e.target.value))
                        }
                        style={inputStyle}
                      />
                      <label htmlFor="dailyCost">Costo giornaliero (€):</label>
                      <input
                        type="number"
                        id="dailyCost"
                        value={dailyCost2}
                        onChange={(e) =>
                          setDailyCost2(parseFloat(e.target.value))
                        }
                        style={inputStyle}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 20,
                        }}
                      >
                        <label style={{ margin: 5, marginBottom: 20 }}>
                        Numero fasce orarie:
                        </label>
                        <select
                          value={numberOfSlots}
                          onChange={(e) =>
                            setNumberOfSlots(parseInt(e.target.value, 10))
                          }
                          style={{ ...selectStyle, width: "60%" }}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                        </select>
                      </div>
                      {numberOfSlots === 1 && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <p style={periodTimeLabelStyle}>
                            Inizio fascia oraria 1:
                            </p>
                            <DatePicker
                              selected={slot1StartingTime}
                              onChange={(date) => setSlot1StartingTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              placeholderText="Inizio fascia oraria 1"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <p style={periodTimeLabelStyle}>
                            Fine fascia oraria 1:
                            </p>
                            <DatePicker
                              selected={slot1EndingTime}
                              onChange={(date) => setSlot1EndingTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              placeholderText="Fine fascia oraria 1"
                            />
                          </div>
                        </div>
                      )}
                      {numberOfSlots === 2 && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",

                              flexDirection: "column",
                            }}
                          >
                            <p style={periodTimeLabelStyle}>
                            Inizio fascia oraria 1:
                            </p>
                            <DatePicker
                              selected={slot1StartingTime}
                              onChange={(date) => setSlot1StartingTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              placeholderText="Inizio fascia oraria 1"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <p style={periodTimeLabelStyle}>
                            Fine fascia oraria 1:
                            </p>
                            <DatePicker
                              selected={slot1EndingTime}
                              onChange={(date) => setSlot1EndingTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              placeholderText="Fine fascia oraria 1"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <p style={periodTimeLabelStyle}>
                            Inizio fascia oraria 2:
                            </p>
                            <DatePicker
                              selected={slot2StartingTime}
                              onChange={(date) => setSlot2StartingTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              placeholderText="Inizio fascia oraria 2"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginBottom: 10,
                            }}
                          >
                            <p style={periodTimeLabelStyle}>
                            Fine fascia oraria 2:
                            </p>
                            <DatePicker
                              selected={slot2EndingTime}
                              onChange={(date) => setSlot2EndingTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              placeholderText="Fine fascia oraria 2"
                            />
                          </div>
                        </div>
                      )}
                      <div>
                        <button
                          onClick={handleParkingUpdate}
                          style={buttonStyle}
                        >
                          Aggiorname
                        </button>
                        <button
                          onClick={() => setSelectedParking("")}
                          style={buttonStyle}
                        >
                          Vicino
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default EditParking;
