import React, { useState, useEffect } from "react";
import {
  auth,
  signInWithEmailAndPassword,
  database,
  ref,
  get,
  onValue,
} from "../config/firebase";
import { useNavigate } from "react-router-dom";
import Login from "../assets/login.jpg";
import Logo from "../assets/logo.png"
const Image = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <img
        src={Login}
        alt="Logo"
        style={{
          flex: 1, // Make sure the image expands to fill the available space
          objectFit: "cover", // Use 'cover' to maintain aspect ratio and cover the entire container
          width: "100%", // Set width to 100% to fill the container horizontally
          height: "100%", // Set height to 100% to fill the container vertically
        }}
      />
    </div>
  );
};
const Logoo = () => {
  return (
    <div style={{ marginBottom: "0px", marginTop:50, backgroundColor:'' }}>
      <img
        src={Logo}
        alt="Logo"
        style={{
          flex: 1, // Make sure the image expands to fill the available space
          width:200,
          marginBottom:0
        }}
      />
    </div>
  );
};
function Signin({ setIsAdminLoggedIn, setIsValetLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // State for error message
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  // Define styling using const variables
  const containerStyle = {
    display: "flex",
    height: "100vh",
  };

  const leftPanelStyle = {
    flex: 1,
    backgroundColor: "#81c57a",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const rightPanelStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "",
    padding: "10px",

    backgroundColor: "grey",
  };

  const inputStyle = {
    width: "50%",
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
  };

  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  };

  const errorMessageStyle = {
    color: "red",
    fontSize: "15px",
  };

  useEffect(() => {
    // Add an event listener to check the screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // You can adjust the breakpoint (768) as needed
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignin = async () => {
    try {
      if (email === "admin321@gmail.com" && password === "admin123@!") {
        // Admin login
        localStorage.setItem("isAdminLoggedIn", "true");
        setIsAdminLoggedIn(true);
        navigate("/adminhome");
        setError(null);
      } else {
        // Regular user login
        const usercre = await signInWithEmailAndPassword(auth, email, password);
        const userid = usercre.user.uid;
        const userRef = ref(database, `users/${userid}`);
  
        onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData && userData.userType === "valet") {
            localStorage.setItem("isValetLoggedIn", "true");
            setIsValetLoggedIn(true);
            localStorage.setItem("alottedCity", userData.alottedCity);
            localStorage.setItem("alottedParking", userData.alottedParking);
  
            // Navigate to "valethome" and pass the user data as params
            navigate("/parking-operator");
          } else {
            setError("Credenziali non valide");
          }
        });
  
        setError(null);
      }
    } catch (error) {
      localStorage.removeItem("isAdminLoggedIn");
      localStorage.removeItem("isValetLoggedIn");
      setError("Credenziali non valide");
      console.error(error.message);
    }
  };
  

  return (
    <div style={containerStyle}>
      {isSmallScreen ? null : (
        <div style={leftPanelStyle}>
          <Image className="left-image" />
        </div>
      )}
      <div style={rightPanelStyle}>
        <Logoo/>
        <h2 style={{ color: "white" }}>ENTRA NELL'AREA RISERVATA</h2>
        {error && <p style={errorMessageStyle}>{error}</p>}
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Parola d'ordine"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <button onClick={handleSignin} style={buttonStyle}>
          ENTRA
        </button>
        {/* Display error message if there's an error */}
      </div>
    </div>
  );
}

export default Signin;
